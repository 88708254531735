import React, {useEffect,useRef, useState } from 'react';
import {useParams} from 'react-router';
import navi from '../../component/navigation/Component-navigation';
import projectConfig from '../../configs/projectData.json';
import projectFlows from '../../configs/projectFlows.json';
import { Auth } from 'aws-amplify';

import mapboxgl from 'mapbox-gl';

import {MapboxStyleDefinition,  MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import {FaDotCircle} from "react-icons/fa";
import {BiCheckCircle, BiCircle} from "react-icons/bi";


import ShareComponent from './components/ShareAccess';
import DraftList from './components/DraftList';
import CreateProject from './components/CreateProject';
import ProjectNotes from './components/ProjectNotes';



export default function Page(){

	// params
	const {id} = useParams();
	const ref = useRef(null);
    const [map, setMap] = useState(null);
    const [draft,setDraft] = useState(null);
    const [user, setUser] = useState(null);
	const [project,setProject] = useState(null);


    console.log(project);

    const mapKey = process.env.REACT_APP_MAPS_KEY;
    mapboxgl.accessToken = mapKey;

    // map styles
    const styles: MapboxStyleDefinition[] = [
        {
            title: "Satellit",
            uri:"mapbox://styles/mapbox/satellite-v9"
        },
        {
            title: "Straße",
            uri:"mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji"
        }
    ];

	// map definition
    const handelMapLoad = (p) => {

        if (typeof ref !== "undefined" && typeof map !== "undefined" && ref?.current && !map  && p) {

            if(typeof  p.mapPolygon !== "undefined" && typeof p?.mapPolygon?.features?.[0]?.properties?.center !== "undefined")
            {
                var mapCenter = p?.mapPolygon?.features?.[0]?.properties?.center;
                var mapZoom = 16;
            }
            else if(typeof p.mapCenter !== "undefined")
            {
                var mapCenter = p.mapCenter;
                var mapZoom = 16;
            }
            else
            {
                var mapCenter = [10, 51.00];
                var mapZoom = 6;
            }


             const map = new mapboxgl.Map({
                 displayControlsDefault: false,
                 container: ref?.current,
                 style: "mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji",
                 center: mapCenter,
                 zoom: mapZoom,
                 maxZoom: 19,
             });

			 map.addControl(new MapboxStyleSwitcherControl(styles, 'Dark','Satellit'));
             map.on('style.load', () => {
                 // Add a data source containing GeoJSON data.
                 map.addSource('mapLayer', {
                    'type': 'geojson',
                    'data': p?.mapPolygon
                 });

                 // Add a new layer to visualize the polygon.
                 map.addLayer({
                     'id': 'mapLayer',
                     'type': 'fill',
                     'source': 'mapLayer', // reference the data source
                     'layout': {},
                     'paint': {
                     'fill-color': '#12ffa9', // blue color fill
                     'fill-opacity': 0.1
                     }
                 });

                 // Add a black outline around the polygon.
                 map.addLayer({
                     'id': 'outline',
                     'type': 'line',
                     'source': 'mapLayer',
                     'layout': {},
                     'paint': {
                     'line-color': '#12ffa9',
                     'line-width': 3
                     }
                 });
			 });

            setMap(map);
        }
        return true;
    }

	// Check phase progress
    const checkProgress = (schema, project) => {
        let given = 0;
        if(schema?.required)
        {
            for (let i = 0; i < schema.required.length; i++) {

		        if( project &&  project[schema.required[i]] ) {
					given++;
		        }
            }
            if((given / schema?.required.length) !== 0)
            {
                return Math.round((given / schema?.required.length) *100);
            }
            else
            {
                return 0;
            }
        }
    }

	// Check Required
    const checkRequired = (schema, project) => {
        var given = 0;

        if(schema.length > 0)
        {
            for (let i = 0; i < schema.length; i++) {
		        if( project &&  project[schema[i]]) {
					given++;
		        }
            }
			if(given === schema.length)
            {
                return true;
            }
			return false;
        }
        return false;
    }

	// get API Data
	async function getProject() {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        var data = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id, requestOptions)
          .then(response => { return response.json() })
          .catch(error => console.log('error', error));

        var raw = {...data[0], ...data[0].json};
        delete(raw.json);
		setProject(raw);
    }

    // get API Data
    async function getDrafts() {

        var headers = new Headers();
        headers.append("Content-Type", "application/json");


        var raw = JSON.stringify(
            {
                schema: "projectsDrafts",
                condition: [
                    {
                        type: "eq",
                        field: "projectsId",
                        values: [
                            id
                        ]
                    }
                ]
            });

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
            .catch(error => console.log('error', error));

        const data = await response.json();
        setDraft(data.rows);
    }

	// add company ID
	async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
    }


	// init load
    useEffect(() => {
		getUser();
		getProject();
        getDrafts();
    }, []);

	var date = new Date(project?.lastUpdateDate*1);

	handelMapLoad(project);

	return(
		 <div>
			 { navi("Grundstück", "/projects/") }
		        <div id={"page-project-profile"} className={"wrapper-content"}>

			     <div className={"projectFlowTitle"}>
				     <h3>{project?.name}<br/>

						{(project?.buildability === "Sofort bebaubar mit Eigentümer-Erlaubnis") ? <span> <FaDotCircle title={"Sofort bebaubar mit Eigentümer-Erlaubnis"} className={"green"}/> Sofort bebaubar mit Eigentümer-Erlaubnis </span> : ""}
						{(project?.buildability  === "Sofort bebaubar - noch kein Kontakt zu Eigentümer") ? <span> <FaDotCircle title={"Sofort bebaubar - noch kein Kontakt zu Eigentümer"} className={"yellow"}/> Sofort bebaubar - noch kein Kontakt zu Eigentümer </span> : ""}
						{(!project?.buildability) ? <span> <FaDotCircle title={"Nicht sofort bebaubar"}  className={"grey"}/> Nicht sofort bebaubar </span>: ""}
				     </h3>
				 </div>

			     <div className={"projectFlowElement"}>
			         <div><div className="map-container" ref={ref} ></div><br/><br/></div>

				     <div className={"projectFlowElementConfigurator"}>
			            { // Get Plotdata
                            projectConfig?.configs?.map(function(d, idx) {

				             if(((checkRequired(d?.require,project)  === true || d?.require.length === 0 ) && user?.attributes?.["custom:type"] !== "supporter") || (user?.attributes?.["custom:type"] === "supporter" && d?.flow === "projectAddress") ) {
		                         return (
									<div className={"processElement" }>
                                        {(checkProgress(projectFlows?.flows[d.flow],project) > 50) ? (<h3 className={"checked"}><BiCheckCircle/><span>{d.title} erfasst </span> <a className={"active"} href={"/project/"+id+"/project/editor/"+d?.flow}>Bearbeiten</a></h3>):(<h3 className={"unChecked"}><BiCircle/><span>{d.title}  </span> <a className={"active"} href={"/project/"+id+"/project/editor/"+d?.flow}>Bearbeiten</a></h3>) }
                                        {
                                            d?.details?.map(function(c, idz){
                                                return (
                                                    <div key={idz} className={"summary "+ c?.class}></div>
                                                )
                                            })
                                        }
	                                </div>
								)
		                     }
                        })}


                        { // get Product List
                            DraftList(project, draft, user?.attributes?.["custom:type"])
                        }

                        { // create project
                            CreateProject(project, draft, user?.attributes?.["custom:serviceMember"])
                        }
					</div>
				</div>

				<div className={"projectFlowElement"}>
					<div>
                        <h3><span>Notizen</span></h3>
                        <div>
                            {ProjectNotes(project) }
                        </div>
			         </div>
			     </div>

				<div className={"projectFlowElement"}>
					<div>
						{(user?.attributes?.["custom:type"] !== "supporter") ? (
                        	<>
				                <ShareComponent/>
							</>
						) : ""  }
				    </div>
                </div>


			 </div>
		 </div>
	);

}
