import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import navi from '../../component/navigation/Component-navigation';

import Table from '@mui/material/Table';
import mapboxgl from 'mapbox-gl';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {BiArrowToRight,BiShare, BiWorld,BiLink, BiHide, BiShowAlt, BiFile} from "react-icons/bi";
import amplify, {API,Auth, Storage, graphqlOperation} from 'aws-amplify';

export default function Overview(){

	const {id,draftId} = useParams();
	const [text,setText] = useState(null);
	const [draft,setDraft] = useState(null);
	const [plot,setPlot] = useState(null);
	const [form,setForm] = useState(null);
	const [loaded,setLoaded] = useState(null);
	var zoom = 1;
    var zoomStep = 0.2;

	// load draft data
	async function getDraft() {

		var headers = new Headers();
			headers.append("Content-Type", "application/json");

		var raw = JSON.stringify(
		{
            schema: "projectsDrafts",
            condition: [
                {
                    type: "eq",
                    field: "id",
                    values: [
                        draftId
                    ]
                }
            ]
        });

		var requestOptions = {
		  method: 'POST',
		  headers: headers,
		  body: raw,
		  redirect: 'follow'
		};

		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
						 .catch(error => console.log('error', error));
	    const responseText = await response.json();


	    if (responseText.rows[0] && responseText.rows[0].published === null)
	    {
	        responseText.rows[0].published = false;
	    }

	    setDraft(responseText.rows[0]);
    }

	// load map
    const handelMapLoad = (draft) => {
   		mapboxgl.accessToken = 'pk.eyJ1IjoibmVidXRvIiwiYSI6ImNsMzV2dmlhYjE4c3oza3A1NmhiOTY0dWEifQ.Pk9SV3osrSrC4kV3WjALMg';

   		const map = new mapboxgl.Map({
   			container: 'map', // container ID
   			style: 'mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji', // style URL
   			center: draft?.json.mapGeoJson?.features?.[0].geometry?.coordinates?.[0][0], // starting position [lng, lat]
   			zoom: 17, // starting zoom
   			projection: 'globe' // display the map as a 3D globe
   		});

   		map.scrollZoom.disable();

   		map.on('load', () => {

   		    map.addSource('plots', {
                'type': 'geojson',
                'data': draft?.json?.mapGeoJson
            });

   			map.addLayer({
   		         'id': 'plots',
   		         'type': 'fill',
   		         'source': 'plots',
   		         'layout': {},
   		         'paint': {
   		         'fill-color': '#000',
   		         'fill-opacity': 0.2
   		    }
   		    });

   		    map.addLayer({
   		         'id': 'outline',
   		         'type': 'line',
   		         'source': 'plots',
   		         'layout': {},
   		         'paint': {
   		         'line-color': '#000',
   		         'line-width': 3
   		    }
   		 });
   		});
   	};

	const handelZoomIn = () => {
		zoom += zoomStep;
        document.getElementById("zoomtext").style.transform = "scale(" + zoom + ")";

	};

	const handelZoomOut = () => {
		if (zoom > zoomStep) {
	        zoom -= zoomStep;
	        document.getElementById("zoomtext").style.transform = "scale(" + zoom + ")";
	    }
	};

	const handelPrint = () => {
		window.print();
	};

	useEffect(() => {
		getDraft();

		fetch(`${process.env.PUBLIC_URL}/formes/file.html`)
        .then((r) => r.text())
        .then(data  => {
            setText(data);
            return;
         })
	},[]);


	if(draft !== null) {
		handelMapLoad(draft);
	}

    return(
        <div>
            { navi("Dokumente / Bauantragsverfahren", "/project/"+id+"/drafts/"+draftId+"/documents/") }
            <div id={"page-project-documents"} className={"wrapper-content"}>
                <div className={"controlls"}>
                    <button onClick={handelZoomOut} id="zoomOut">-</button>
                    <button onClick={handelZoomIn} id="zoomIn">+</button>
                    <button onClick={handelPrint} className={"noPrint"}>⎙</button>
                </div>

		        <div className={"documentEditior"}>
		            {ReactHtmlParser(text)}
		        </div>
		    </div>
		</div>
    );


}

