import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFileOn} from "react-icons/ci";

export default function Overview(){

	let {id, draftId} = useParams();

	return(
		<div>
			{ navi("Dokumente / Öffentliche Mittel /  Niedersachsen", "/project/"+id+"/drafts/"+draftId+"/documents/category") }
			<div id={"page-project-documents"} className={"wrapper-content"}>
				<p>
					Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
				</p>
				<div className={"documentSplit"}>
					<div className={"documentOverview document"}>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Antrag-Wohnberechtigungsschein-–-Einkommenserklärung-Haushaltsvorstand.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Antrag auf Erteilung eines Wohnberechtigungsscheins
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Baubeschreibung-Vordr.-1021.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Baubeschreibung gem. § 9 BauVorlVO
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Bestätigung-über-die-Verwendung-von-Eigenmitteln-Vordr.-4004.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Bestätigung über die Verwendung von	Eigenmitteln
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Datenschutzhinweis.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Datenschutzhinweis
								</div>
							</a>
						</div>

						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Eigentumsförderung.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Eigentumsförderung
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Einkommenserklärung-Haushaltsangehörige-Anlage-1.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Einkommenserklärung Haushaltsangehörige Anlage-1
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Förderantrag-Eigentumsmassnahmen-Vordruck-1010.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Förderantrag Eigentumsmassnahmen Vordruck
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Identitätsfeststellung-gemäß-Geldwäschegesetz-natürliche-Personen-Vordr.-1005.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Identitätsfeststellung gemäß Geldwäschegesetz natürliche Personen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Merkblatt-für-beizufügende-Unterlagen.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Merkblatt für beizufügende Unterlagen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Notarielle-Bestätigung-Vordr.-1152.doc`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Notarielle Bestätigung
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Selbstauskunft-Eigentumsmaßnahmen-Vordr.-4207.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Selbstauskunft Eigentumsmaßnahmen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Stellungnahme-zu-den-gegenwärtigen-Wohnverhältnissen-Vordr.-1159.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Stellungnahme zu den gegenwärtigen Wohnverhältnissen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/ni/Verwendungsnachweis-Vordr.-4009.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Verwendungsnachweis
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

