import React from 'react';
import navi from '../../component/navigation/Component-navigation';

export default function Page(){

    return(
		<div>
			{ navi("Hilfe & Anleitungen", "/") }
			<div id={"page-help-overview"} className={"wrapper-content"}>


				<h3>Login / Abmelden</h3>

				<div className={"helpGird"}>
					<div>
						<b>Anmelden im Mitgliederbereich</b>
						<iframe allowfullscreen allowfullscreen="allowfullscreen"          mozallowfullscreen="mozallowfullscreen"         msallowfullscreen="msallowfullscreen"         oallowfullscreen="oallowfullscreen"         webkitallowfullscreen="webkitallowfullscreen" width="300" height="180" src="https://www.youtube.com/embed/rGcuX8HqbXk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					</div>
					<div>
                        <b>Abmelden aus dem  Mitgliederbereich</b>
                        <iframe allowfullscreen="allowfullscreen"         mozallowfullscreen="mozallowfullscreen"         msallowfullscreen="msallowfullscreen"         oallowfullscreen="oallowfullscreen"         webkitallowfullscreen="webkitallowfullscreen" width="300" height="180" src="https://www.youtube.com/embed/y_d7Fq9RLvk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
				</div>

				<h3>Bauleitplaungen</h3>
                <div className={"helpGird"}>
                    <div>
                        <b>Bauleitpläne einsehen</b>
                        <iframe allowfullscreen="allowfullscreen"         mozallowfullscreen="mozallowfullscreen"         msallowfullscreen="msallowfullscreen"         oallowfullscreen="oallowfullscreen"         webkitallowfullscreen="webkitallowfullscreen" width="300" height="180" src="https://www.youtube.com/embed/sLCQrCL4tK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>

                <h3>Projekte erfassen & Bearbeiten</h3>
                <div className={"helpGird"}>

                    <div>
                        <b>Projekt erstellen + Grunddaten erfassen</b>
                        <iframe allowfullscreen="allowfullscreen"         mozallowfullscreen="mozallowfullscreen"         msallowfullscreen="msallowfullscreen"         oallowfullscreen="oallowfullscreen"         webkitallowfullscreen="webkitallowfullscreen" width="300" height="180" src="https://www.youtube.com/embed/OtcJbqHMWNg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
					<div>
						<b>Projekt Übersicht</b>
						<iframe allowfullscreen="allowfullscreen"         mozallowfullscreen="mozallowfullscreen"         msallowfullscreen="msallowfullscreen"         oallowfullscreen="oallowfullscreen"         webkitallowfullscreen="webkitallowfullscreen" width="300" height="180" src="https://www.youtube.com/embed/Df9zH4rsrgQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					</div>
                </div>

				<h3>Benuzterprofil</h3>
                <div className={"helpGird"}>
                    <div>
                        <b>Profil bearbeiten</b>
                        <iframe allowfullscreen="allowfullscreen"         mozallowfullscreen="mozallowfullscreen"         msallowfullscreen="msallowfullscreen"         oallowfullscreen="oallowfullscreen"         webkitallowfullscreen="webkitallowfullscreen" width="300" height="180" src="https://www.youtube.com/embed/vFFUEyTTnQc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
			</div>>
		</div>
    );

}