import React,{useState, useEffect} from 'react';
import {v4} from 'uuid';
import { useNavigate } from 'react-router-dom';
import navi from '../component/navigation/Component-navigation';


export default function Page(){

	var a = 0;
	const navigate = useNavigate();

    const queryParameters             = window.location.search;
    const urlParams                   = new URLSearchParams(queryParameters);
    const id                          = (urlParams.get('projectId')) ? urlParams.get('projectId') : null;
    const type                        = (urlParams.get('type')) ? urlParams.get('type') : null;
    const name                        = (urlParams.get('name')) ? urlParams.get('name') : null;
    const draftId                     = (urlParams.get('draftId')) ? urlParams.get('draftId') : null;


	var requestOptions = {
		method: 'DELETE',
		redirect: 'follow'
	};


	// handel number field changes
	async function handlingDeleteProject() {

		var headers = new Headers();
                headers.append("Content-Type", "application/json");

		var requestOptions = {
            method: 'DELETE',
            headers: headers,
            redirect: 'follow'
        };

        if(type === "project") {
           const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id, requestOptions)
                .catch(error => console.log('error', error));

		   navigate('/projects/');

       }
       else if(type === "draft") {
           const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id+"/drafts/"+draftId, requestOptions)
                .catch(error => console.log('error', error));


           navigate('/project/'+id );
       }
       else if(type === "sharedAccess") {
           const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/share/"+draftId, requestOptions)
                .catch(error => console.log('error', error));

           navigate('/project/'+id );
       }
       else {
           return
       }
    }

	useEffect(() => {

	}, []);


    if(type === "project") {
       return (
            <div>
               { navi("Grundstück löschen", "/projects/") }

                <div id={"page-drafts-delete"} className={"wrapper-content"}>
                   <div className={"splashScreen"}>
                       <div>

                           <h3> Projekt <b>{name}</b> löschen</h3>
                           <p>
                               Sind sie sicher, dass das Projekt unwiderruflich gelöscht werden soll? Nach Löschung kann der das Projekt nicht wieder hergestellt werden. Sämtliche Angaben und Informationen sowie gezeichneten Parzellen werden ebenfalls entfernt.
                           </p>
                       </div>
                       <div className={"primaryActions"}>
                           <a className={"secondary"} href={"/projects/"}>Zurück</a>
                           <a className={"highlighed"} onClick={() => { handlingDeleteProject() }} >Projekt löschen</a>
                       </div>

                   </div>

                </div>
            </div>
       	);
    }
    else if(type === "draft") {
       return (
            <div>
               { navi("Projekt löschen", "/project/"+id) }

                <div id={"page-drafts-delete"} className={"wrapper-content"}>
                   <div className={"splashScreen"}>
                       <div>

                           <h3> Bebauungskonzepte <b>{name}</b> löschen</h3>
                           <p>
                               Sind sie sicher, dass das Projekt unwiderruflich gelöscht werden soll? Nach Löschung kann der das Projekt nicht wieder hergestellt werden. Sämtliche Angaben und Informationen sowie gezeichneten Parzellen werden ebenfalls entfernt.
                           </p>
                       </div>
                       <div className={"primaryActions"}>
                           <a className={"secondary"} href={"/project/"+id}>Zurück</a>
                           <a className={"highlighed"} onClick={() => { handlingDeleteProject() }} >Löschen</a>
                       </div>

                   </div>

                </div>
            </div>
       	);
    }
    else if(type === "sharedAccess") {
       return (
       		<div>
                   { navi("Zugriff entfernen", "/project/"+id) }

                    <div id={"page-drafts-delete"} className={"wrapper-content"}>
                       <div className={"splashScreen"}>
                           <div>

                               <h3>Zugriff <b>{name}</b> löschen</h3>
                               <p>
                                   Sind sie sicher, dass der genannte Zugriff entzogen werden soll?. Sofern Sie den Zugriff mit dritten geteilt haben, wird dieser umgehend entzogen.
                               </p>
                           </div>
                           <div className={"primaryActions"}>
                               <a className={"secondary"} href={"/project/"+id}>Zurück</a>
                               <a className={"highlighed"} onClick={() => { handlingDeleteProject() }} >Löschen</a>
                           </div>

                       </div>

                    </div>
                </div>
       	);
    }

    return("")
}