export const formFields = {
    signIn: {
      username: {
        label: 'Benutzer',
        placeholder: 'Benutzername eingeben',
        isRequired: true,
      },
    },
    signUp: {
      given_name: {
        placeholder: 'Vorname eingeben',
        isRequired: true,
        order: 12,
      },
      family_name: {
        placeholder: 'Nachname eingeben',
        isRequired: true,
        order: 13,
      },
      email: {
        label: 'E-Mailadresse',
        placeholder: 'E-Mailadresse  eingeben',
        isRequired: true,
        order: 14,
      },

      username: {
        label: 'Benutzername',
        placeholder: 'Benutzername eingeben',
        isRequired: true,
        order: 16,
      },
      password: {
        label: 'Passwort',
        placeholder: 'Passwort eingeben',
        isRequired: true,
        order: 18,
      },
      confirm_password: {
        label: 'Passwort (Wdh.)',
        placeholder: 'Passwort eingeben',
        isRequired: true,
        order: 19,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Bitte Passwort eingeben',
      },
    },
    resetPassword: {
      username: {
        placeholder: 'Bitte E-Mailadresse eingeben',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Bitte Authorisierungs Code aus E-Mail eingeben:',
        label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Bitte Passwort eingeben:',
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Anmeldung Bestätigen',
        placeholder: 'Bitte Bestätigungscode eingeben:',
        isRequired: false,
      },
    },
  };