/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://s7ffdk6ro5cpfl5o5h4mckzvna.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:be6d4633-70dc-47f1-9ce8-4d87d95dac18",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_qNYeItWFy",
    "aws_user_pools_web_client_id": "10vet2tm6h0lkd2fk32e7qvb5r",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hfhera087d5aa0f5934a369881f3d482aae816150917-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
