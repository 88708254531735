import React, {useEffect, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import { DataGrid, deDE } from '@mui/x-data-grid';
import {listGeoCommunities} from '../../graphql/queries';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import projectFlows from '../../configs/propertiesData.json';


export default function Page(){



	const [list,setList] = useState(projectFlows?.properties);
	const navigate = useNavigate();


	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Bezeichnung',
			width: 300,
			renderCell: (cellValues) => {return (cellValues.row.name) ? cellValues.row.name : "-"},
		},
		{
			field: 'rooms',
			headerName: 'Zimmer',
			sortable: true
		},
		{
			field: 'size',
			headerName: 'Wohnfläche',
			sortable: true
		},
		{
			field: 'dimension',
			headerName: 'Fimension',
			sortable: true
		},
		{
			field: 'price',
			headerName: 'Preis',
			sortable: true
		}
	];



	// render
	return(
		<div>
		    { navi("Einstellungen / Haus Datenbank", "/user",true) }
		    <div id={"page-user-houseDatabase"} className={"wrapper-content"}>
		        <div>
                    <div>
                        <h3>Willkommen in Ihrer Haus Datenbank</h3>
                        <p>
                            Sie haben hier die Möglichkeit eigenen Haustypen für Ihre Bebauungskonzpten zu hinterlegen.
						</p>
					</div>
				</div>

		        <div className={"potentialList"}  style={{ height: 900, paddingTop:'21px',  }}>
		            {(list !== null)? (
		                <DataGrid
		                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
		                    rows={list}
		                    columns={columns}
		                    onRowClick={(params: GridRowParams ) => {navigate('/houseDatabase/'+params?.row.id )}}
		                    checkboxSelection={false}
		                    disableRowSelectionOnClick={false}
		                    disableVirtualization={true}
		                    editMode={false}
		                />
		            ):("")}
		        </div>
		    </div>
		</div>
	);
}