import React, {useEffect, useState } from 'react';
import logo from '../../../assets/image/logo3.png';
import { BsPatchQuestion} from "react-icons/bs";
import { BiArrowBack,BiLayerPlus, BiUser, BiFont} from "react-icons/bi";
import { Auth } from 'aws-amplify';
import {  Link } from 'react-router-dom';

export default function ComponentNavigation(page, redirect = null, user = null){

    return(
        <div className={"navigation wrapper-content"}>
            <div id={"logo"}>
                <img src={logo} alt="Home & Family" />
	  	  	    <h1>{page}</h1>
            </div>
	    </div>
    );

}