import React, { useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { TbExternalLink } from "react-icons/tb";

export function SignUp() {

    return (
        <div className={'signupLegal'}>

            <Authenticator.SignUp.FormFields />

            <div className='relative flex mt-8 auth-checkbox pt-8 border-t border-gray-500 gap-x-3'>
                <div className='flex h-6 items-center'>
                    <input
                        id='imprint'
                        name='imprint'
                        required
                        type='checkbox'
                        className='h-4 w-4 rounded border-gray-900 text-indigo-600 focus:ring-indigo-600'
                    />
                </div>
                <div className='text-sm leading-6'>
                    <a
                        href={'https://hf-projekt.de/agb-allgemeine-geschaeftsbedingungen/'}
                        target={'_blank'}
                        htmlFor='candidates'
                        className='font-bold flex text-gray-900'
                        rel='noreferrer'>
                        Allgemeine Geschäftsbedingungen (AGB)<TbExternalLink/>
                    </a>
                    <p className='text-gray-900'>
                        Ich habe die Allgemeine Geschäftsbedingungen (AGB) gelesen und akzeptiere
                        diese.
                    </p>
                </div>
            </div>
            <div className='relative flex mt-4 auth-checkbox  items-center gap-x-3'>
                <div className='flex h-6'>
                    <input
                        id='privacy'
                        required
                        name='privacy'
                        type='checkbox'
                        className='h-4 w-4 rounded border-gray-900 text-indigo-600 focus:ring-indigo-600'
                    />
                </div>
                <div className='text-sm leading-6'>
                    <a
                        href={'https://hf-projekt.de/datenschutz/'}
                        target={'_blank'}
                        htmlFor='privacy'
                        className='font-bold flex text-gray-900'
                        rel='noreferrer'>
                        Datenschutzbestimmung<TbExternalLink/>
                    </a>
                    <p className='text-gray-900'>
                        Ich habe die Datenschutzbestimmung gelesen und
                        akzeptiere diese.
                    </p>
                </div>
            </div>
        </div>
    );
}
