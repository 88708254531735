import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFileOn} from "react-icons/ci";


export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente / Öffentliche Mittel / Nordrhein-Westfalen", "/project/"+id+"/drafts/"+draftId+"/documents/category") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <p>
                    Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
                </p>
                <div className={"documentSplit"}>
                    <div className={"documentOverview document"}>
	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/5401-anlage-zur-einkommenserklaerung-final-15032022.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                                Anlage zur Einkommenserklärung
	                            </div>
	                        </a>
	                    </div>
	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/6003-selbstauskunft-final-15032022.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                               Selbstauskunft
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/6004-selbsthilfeleistungen-eigentumsmassnahmen-final-03032022.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                               Selbsthilfeleistungen Eigentumsmaßnahmen
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/6008-fachunternehmenserklaerung-bauen-mit-holz-15032022.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                              Fachunternehmererklärung Bauen mit Holz
	                            </div>
	                        </a>
	                    </div>


	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/ua-6001-fo-rderantrag-selbst-genutztes-wohneigentum-09032022.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                              Förderantrag selbst genutztes Wohneigentum
	                            </div>
	                        </a>
	                    </div>

	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/ua-6002-aenderungsantrag-ergaenzungsdarlehen-baukostensteigerung-neubau-eigentum.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                              Änderungsantrag Ergänzungsdarlehen Baukostensteigerungen selbstgenutztes Wohneigentum
	                            </div>
	                        </a>
	                    </div>
	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/wohnraumfoerderung-einwilligungserklaerung-wohnweb.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
	                              Datenschutzrechtliche Einwilligunserklärung
	                            </div>
	                        </a>
	                    </div>
					 </div>
                 </div>
			 </div>
        </div>
    );
}

