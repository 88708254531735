import React, {useContext, useState} from 'react';
import {FileContext} from "../../../../context/FileContext";
import OrderFileUploader from "./orderFileUploader";




const OrderUploadHandler = (data) => {
    const {id,draftId} = useContext(FileContext);
    var settings = [
        {
            name: "Projekt-Unterlagen *",
            path:"project/"+id+"/"+draftId.replaceAll("-", '/')+"/Documents"
        }
    ];


    if(typeof data?.data?.attributes?.["custom:serviceMember"] !== "undefined" && data?.data?.attributes?.["custom:serviceMember"] === "1") {
        settings = [
            {
                name: "Projekt-Unterlagen *",
                path:"project/"+id+"/"+draftId.replaceAll("-", '/')+"/Documents"
            },
            {
                name: "Genossenschaftsunterlagen *",
                path:"project/"+id+"/"+draftId.replaceAll("-", '/')+"/cooperativeDocuments"
            }
        ];
    }


    return (
        <div>
            {settings?.map((setting) => (
                <div style={{marginBottom:"24px"}}>
                    {OrderFileUploader(setting?.name, setting?.path)}
                </div>
            ))}
        </div>

    );
};

export default OrderUploadHandler;
