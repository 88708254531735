import React, {useEffect,useState} from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import navi from '../../component/navigation/Component-navigation';
import TextField from '@mui/material/TextField';


export default function Page(){

	let avatarImage = null;
	const navigate = useNavigate();
	const [user,setUser] = useState(null);
	const [userChanges,setUserChanges] = useState(null);

	// add company ID
	async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user?.attributes);
        setUserChanges(user?.attributes);
    }

    async function saveUser() {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, userChanges);
        storeUserData();
        navigate('/user');
    }

	async function storeUserData() {

        		var headers = new Headers();
        			headers.append("Content-Type", "application/json");

        		var raw = JSON.stringify(
        		{
        		    companyId: user?.["custom:companyID"],
        		    userId: user?.["sub"],
                    json: JSON.stringify({
						given_name: user?.["given_name"],
						family_name: user?.["family_name"],
						company: user?.["custom:company"],
						phone: user?.["custom:phone"],
						mobile: user?.["custom:mobile"],
						email: user?.["custom:email"],
						type: user?.["custom:type"]
                    })
                });

        		var requestOptions = {
        		  method: 'POST',
        		  headers: headers,
        		  body: raw,
        		  redirect: 'follow'
        		};

        		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/users", requestOptions)
        						 .catch(error => console.log('error', error));

        }

	useEffect(() => {
		getUser();
	},[]);

	const handelChanges = (e) => {
		var us = {...user}
			us[e.target.name] = e.target.value;

		setUserChanges(us);
		setUser(us);
    }

	if(user !== null)
	{
		return(
            <div>
                { navi("Einstellungen / Mein Profil", "/user",true) }
                <div id={"page-user-setting"} className={"wrapper-content"}>
                    <div>
                        <div>
                            <h3>Willkommen in Ihrem Profil</h3>
                            <p>
                                Sie haben hier die Möglichkeit die Angaben zu Ihrem Benutzer zu verwalten. Bitte bedenken Sie das Rufnummern sowie E-Mailadressen auch in den Intergrationen von Home & Family wiederverwendet werden.
                            </p>
                        </div>
                    </div>

	                    <div style={{ paddingTop:'21px',  }}>
	                        <TextField
	                           name={"custom:company"}
	                           label={"Unternehmens Name"}
	                           onChange={handelChanges}
	                           style={{width: "100%", marginLeft: "0"}}
	                           value={(userChanges?.["custom:company"]) ? userChanges["custom:company"] : ""}
	                        />
	                        <TextField
	                           name={"given_name"}
	                           label={"Vorname"}
	                           type={"text"}
	                           onChange={handelChanges}
	                           style={{width: "49%", marginLeft: "0"}}
	                           value={(userChanges?.["given_name"]) ? userChanges["given_name"] : ""}
	                        />
	                        <TextField
	                           name={"family_name"}
	                           label={"Nachname"}
	                           onChange={handelChanges}
	                           style={{width: "49%", marginLeft: "2%"}}
	                           value={(userChanges?.["family_name"]) ? userChanges["family_name"] : ""}
	                        />
	                       </div>
	                       <div>
	                           <TextField
	                              name={"custom:phone"}
	                              label={"Telefon"}
	                              onChange={handelChanges}
	                              style={{width: "100%", marginLeft: "0"}}
	                              value={(userChanges?.["custom:phone"]) ? userChanges["custom:phone"]: ""}
	                           />
	                           <TextField
	                              name={"custom:mobile"}
	                              label={"Mobil"}
	                              onChange={handelChanges}
	                              style={{width: "100%", marginLeft: "0"}}
	                              value={(userChanges?.["custom:mobile"]) ? userChanges?.["custom:mobile"] : ""}
	                           />
	                           <TextField
	                              name={"custom:email"}
	                              label={"E-Mail"}
	                              onChange={handelChanges}
	                              style={{width: "100%", marginLeft: "0"}}
	                              value={(userChanges?.["custom:email"]) ? userChanges["custom:email"] : ""}
	                           />
	                       </div>
	                       <div>
	                             <h3>FLOWFACT Schnittstellen</h3>
								 <p>
                                    Hinterlegen Sie Ihr FLOWFACT API Token um Projekten an FLOWFACT übertragen zu können. Ihr Token finden Sie <u><a target={"_blank"} href={"https://apps.flowfact.com/settings/apiAccess"}>hier</a></u>.
                                </p><br/><br/>
								<TextField
                                  name={"custom:apiFlowfactToken"}
                                  label={"FLOWFACT Api Token"}
                                  onChange={handelChanges}
                                  style={{width: "100%", marginLeft: "0"}}
                                  value={(userChanges?.["custom:apiFlowfactToken"]) ? userChanges["custom:apiFlowfactToken"] : ""}
                               />
	                       </div>
					       <div>
	                             <h3>OnOffice Schnittstellen</h3>
								 <p>
                                    Hinterlegen Sie Ihr onOffice API Token und Security Token um Projekten an onOffice übertragen zu können. Ihr Token finden Sie under <b>onOffice -> Extra -> Einstellungen -> Benutzer</b>.
                                </p><br/><br/>
								<TextField
                                  name={"custom:onOfficeToken"}
                                  label={"onOffice API-Token"}
                                  onChange={handelChanges}
                                  style={{width: "100%", marginLeft: "0"}}
                                  value={(userChanges?.["custom:onOfficeToken"]) ? userChanges["custom:onOfficeToken"] : ""}
                               />
							   <TextField
                                  name={"custom:onOfficeSecretToken"}
                                  label={"onOfice Secret-Token"}
                                  onChange={handelChanges}
                                  style={{width: "100%", marginLeft: "0"}}
                                  value={(userChanges?.["custom:onOfficeSecretToken"]) ? userChanges["custom:onOfficeSecretToken"] : ""}
                               />
							   <p>
								   Sofern Sie Ihr Immobilien in onOffice automatisch einem Ansprechpartner zuweisen wollen, so hinterlegen Sie hier bitte die Kontakt-ID (z.B. 119).
							   </p>
							   <TextField
                                  name={"custom:onOfficeContactId"}
                                  label={"Kontakt ID"}
                                  onChange={handelChanges}
                                  style={{width: "100%", marginLeft: "0"}}
                                  value={(userChanges?.["custom:onOfficeContactId"]) ? userChanges["custom:onOfficeContactId"] : ""}
                               />
							   <p>
								   Sofern Sie Ihr Immobilien automatisch im ImmobilienScout24 der Town&Country Gruppe zuweisen möchten, so hinterlegen Sie hier bitte Ihre.
							   </p>
							   <TextField
								   name={"custom:onOfficePrimaryId"}
								   label={"Immoscout Gruppennummer(10 Stellig)"}
								   onChange={handelChanges}
								   style={{width: "100%", marginLeft: "0"}}
								   value={(userChanges?.["custom:onOfficePrimaryId"]) ? userChanges["custom:onOfficePrimaryId"] : ""}
							   />


	                       </div>
	                       <div className={"buttonBar"}>
	                            <a className={"active button"} onClick={() => { saveUser() }}>Speichern</a>
	                       </div>
                    </div>
            </div>
        );
	}


}