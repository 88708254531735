import React from 'react';

export default function Page(){

    return(
        <div id={"page-users-overview"} className={"wrapper-content"}>
			User -> Overview
		</div>
    );

}