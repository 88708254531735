import React,{useState, useEffect} from 'react';
import {v4} from 'uuid';
import {useParams} from 'react-router';
import awsconfig from '../../aws-exports';
import { useNavigate } from 'react-router-dom';
import * as mutations from '../../graphql/mutations';
import amplify, { API, Auth } from 'aws-amplify';
import * as turf from '@turf/turf';

export default function Page(){
	var a = 0;
	const navigate = useNavigate();
	const {district, geoId} = useParams();
    const [id,setId] = useState(null);
    const [stopper,setStopper] = useState(false);
    const [polygons, setPolygons] = useState(null);
	amplify.configure(awsconfig);

	async function createProject(id) {
        var headers = new Headers();
            headers.append("Content-Type", "application/json");
        var user = await Auth.currentAuthenticatedUser();

        if(user?.attributes["custom:companyID"] !== null && polygons !== null)
        {
            for (let i = 0; i < polygons?.[0].mapGeoJson.features.length; i++) {
                var poly = polygons?.[0].mapGeoJson.features[i];

                if(poly.id === geoId) {

					var center   = turf.center(poly);
					poly.properties.center = center.geometry.coordinates;

                    var raw = JSON.stringify({
                      userId: user?.attributes["sub"],
                      companyId: user?.attributes["custom:companyID"],
                      name:"Neues Projekt",
                      json:{
                        mapPolygon:{
                            features:[poly],
                            type: "FeatureCollection"
                        },
                        potentialId:poly.id
                      },
                      creationDate: Date.now(),
                      lastUpdateDate: Date.now(),
                      status: 1
                    });

                    var requestOptions = {
                      method: 'POST',
                      headers: headers,
                      body: raw,
                      redirect: 'follow'
                    };

                    const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects", requestOptions)
                                     .catch(error => console.log('error', error));
                    var result = await response.text();
                    if(result !== null)
                    {
                         navigate('/project/'+result );
                    }
                }
            }
        }
    }

	async function getPotentials() {

    		var headers = new Headers();
    			headers.append("Content-Type", "application/json");

    		var raw = JSON.stringify(
    		{
                schema: "geoPotentials",
                condition: [
                    {
                        type: "eq",
                        field: "governmentCommunitiesDistrictsId",
                        values: [
                            district
                        ]
                    }
                ]
            });

    		var requestOptions = {
    		  method: 'POST',
    		  headers: headers,
    		  body: raw,
    		  redirect: 'follow'
    		};

    		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
    						 .catch(error => console.log('error', error));

    	    const responseText = await response.json();
    	    setPolygons(responseText.rows);
        }

		useEffect(() => {

		if(district && geoId && polygons === null) {
			getPotentials();
		}

		if(polygons !== null && stopper === false) {
            setStopper(true);
            createProject();
        }

	}, [polygons]);

	return;
}