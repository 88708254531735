import React from 'react';
import navi from '../../component/navigation/Component-navigation';

export default function Page(){

    return(
		<div>
			{ navi("Nachrichten", "/") }
			<div id={"page-notification-overview"} className={"wrapper-content"}>
				Notification -> Overview
			</div>
		</div>
    );

}