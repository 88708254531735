import React, {useEffect,useState} from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, Link } from 'react-router-dom';
import navi from '../../component/navigation/Component-navigation';
import {TfiSettings, TfiPowerOff} from "react-icons/tfi";
import {AiOutlineUser} from "react-icons/ai";
import {BsCodeSlash, BsHouses} from "react-icons/bs";
import imageProfile from '../../assets/image/profile.jpg';
import TextField from '@mui/material/TextField';

export default function Page(){

	let avatarImage = null;
	const [user,setUser] = useState(null);
	const navigate = useNavigate();
	// add company ID
	async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user?.attributes);
    }

	useEffect(() => {
		getUser();
	},[]);

	const logOut = () => {
        Auth.signOut();
        navigate('/' );
    }

	if(user !== null)
	{
		return(
            <div>
                { navi("Einstellungen", "/",true) }
                <div id={"page-user-profile"} className={"wrapper-content"}>
                    <div className={"splashScreen"}>
                        <div>
                           <img src={imageProfile} />
                           <h3>{user?.["given_name"]} {user?.["family_name"]}</h3>
                           <p>{user?.["custom:company"]} | Rolle: {(user["custom:type"]) ? user["custom:type"] : "Standard Benutzer"}</p>
                       </div>

                       <div  className={"primaryActions "}>

						   <Link to={`/user/settings`} className={"secondary"}><AiOutlineUser/>Profil</Link>

						   {(user?.["custom:type"] !== "supporter") ? (
						        <Link to={`/user/integrations`} className={"secondary"}><BsCodeSlash/>Integration</Link>
						   ):"" }

                           <a className={"highlighed"} onClick={logOut}><TfiPowerOff/>  Abmelden</a>
                       </div>
                    </div>
                </div>
            </div>
        );
	}


}