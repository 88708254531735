import React,{useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router';
import {Editor} from '@tinymce/tinymce-react';

export default function Page(project){

	const {id} = useParams();
	const [notes,setNotes] = useState(null);
    const editorRef = useRef(null);


    // store project
    const save = () => {
        if (editorRef.current) {

            // set header
            var headers = new Headers();
                headers.append("Content-Type", "application/json");

            // Build json body
            var raw                 = {};
                raw.status          = project.status;
                raw.lastUpdateDate  = Date.now();
                raw.name            = project.name;
                raw.json            = project;
                raw.json.notes      = editorRef.current.getContent();

                delete(raw.json.id);
                delete(raw.json.userId);
                delete(raw.json.companyId);
                delete(raw.json.lastUpdateDate);
                delete(raw.json.json);
                delete(raw.json.creationDate);
                delete(raw.json.name);

            // build request
            var requestOptions = {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(raw),
              redirect: 'follow'
            };

            // send request
            const response = fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id, requestOptions).catch(error => console.log('error', error));
        }
    }

    return (
        <div className={"notes"}>
          <Editor
            apiKey='n9rdktq50mgdre0azy6kg134josz7ik7qwu6ixit27jsudam'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(project?.notes) ? project?.notes : "" }
            init={{
              height: 500,
              menubar: false,
              plugins: [],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px }'
            }}
          />
          <button className={"button"} onClick={save}>Speichern</button>
        </div>
    );
}
