import React, {useEffect, useState } from 'react';
import logo from '../../assets/image/logo3.png';
import { BsPatchQuestion} from "react-icons/bs";
import { BiArrowBack,BiLayerPlus, BiUser, BiFont} from "react-icons/bi";
import { Auth } from 'aws-amplify';
import {  Link } from 'react-router-dom';

export default function ComponentNavigation(page = null, redirect = null, user = null){

    return(
      <div className={"navigation wrapper-content"}>
        { redirect ? (
		        <div id={"logo"}>
		            <Link to={`/`}><img src={logo} alt="Home & Family" /></Link>
		            <Link to={redirect}><BiArrowBack/></Link>
					 <h2>{page}</h2>
		        </div>
		    ) : (
		        <div id={"logo"}>
		            <img src={logo} alt="Home & Family" />
					<h1>{page}</h1>
		        </div>
	    )}


		{ user ? (
			<div id={"controlls"}></div>
		) : (
		    <div id={"controlls"}>
		         <Link title={"Hilfe: Wie geht das hier?"} to={"/help"}><BsPatchQuestion  /></Link>
		         <Link title={"Grundstück erstellen"} to={"/projects/create"}><BiLayerPlus/></Link>
		         <Link title={"Benutzerprofil"} to={"/user"}><BiUser/></Link>
		    </div>
		)}

	  </div>
    );

}