import React,{useState, useEffect} from 'react';
import {v4} from 'uuid';
import {useParams} from 'react-router';
import awsconfig from '../../aws-exports';
import { useNavigate } from 'react-router-dom';
import * as mutations from '../../graphql/mutations';
import amplify, { API, Auth } from 'aws-amplify';

export default function Page(){

	var a = 0;
	const navigate = useNavigate();
    const {id, json} = useParams();

	amplify.configure(awsconfig);

	async function createProject() {
        var headers = new Headers();
            headers.append("Content-Type", "application/json");
        var user = await Auth.currentAuthenticatedUser();

        if(user?.attributes["custom:companyID"] !== null )
        {
            var raw = JSON.stringify({
              userId: user?.attributes["sub"],
              companyId: user?.attributes["custom:companyID"],
              projectsId: id,
              name:"Neuer Entwurf",
              creationDate: Date.now(),
              lastUpdateDate: Date.now(),
              status: 1,
              published: false,
            });

            var requestOptions = {
              method: 'POST',
              headers: headers,
              body: raw,
              redirect: 'follow'
            };

            const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+id+"/drafts", requestOptions)
                             .catch(error => console.log('error', error));
            var result = await response.text();
            if(result !== null)
	        {
	             navigate('/project/'+id+"/drafts/"+result );

	        }
        }
    }

	useEffect(() => {

		if(a === 0)
		{
			createProject();
			a = 2;
		}

	}, []);

	return;
}