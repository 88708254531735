import React,{useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {SlSizeFullscreen} from "react-icons/sl";
import {HiOutlineCurrencyEuro} from "react-icons/hi";
import {BiCircle, BiScreenshot,BiCheckCircle} from "react-icons/bi";
import {RiBuilding2Line, RiLayoutMasonryLine } from "react-icons/ri";
import properties from '../../../configs/propertiesData.json';
import { IoIosArrowRoundForward } from "react-icons/io";
import Button from "@mui/material/Button";

export default function Page(project, draft, usertype){


		const {id} = useParams();

		function* entries(obj) {
	        if(typeof obj !== "undefined")
	            {
	                for (let key of Object.keys(obj)) {
	                    yield [key, obj[key]];
	                }
	            }
	        }

		if(usertype !== "supporter" && usertype !== null) {
			return (

			<div>

				{(draft?.length > 0)  ? (<h3 className={"checked"}><BiCheckCircle/><span>Projekt planen</span></h3>):(<h3 className={"unChecked"}><BiCircle/> <span>Projekt planen</span></h3>) }

				<div>

		             <div className={"cardContainer"}>
		                 { draft?.map(function(d, idx) {

		                    var date =  new Date(d.lastUpdateDate*1);
		                        date =  date.toLocaleString("de-DE").slice(0, -3)+" Uhr";

		                    var totalPlots = 0;
		                    var totalMinPlotPrice = 0;
		                    var totalMaxPlotPrice = 0;
		                    var totalMaxLivingArea = 0;
		                    var totalMinLivingArea = 0;
		                    var totalMinPlotArea = 0;
		                    var totalMaxPlotArea = 0;
		                    var totalPrice = 0;
		                    var totalMinUnits = 0;
		                    var totalMaxUnits = 0;

		                    for (let [key, value] of entries(d.json.mapData)) {
		                        if(value.area){
		                            if(totalMinPlotArea === 0 && totalMaxPlotArea === 0)
		                            {
		                                totalMinPlotArea = value.area;
		                                totalMaxPlotArea = value.area;
		                            }

		                            if(totalMinPlotArea > value.area)
		                            {
		                                totalMinPlotArea = value.area;
		                            }

		                            if(totalMaxPlotArea < value.area)
		                            {
		                                totalMaxPlotArea = value.area;
		                            }

		                        }

		                        if(value.purchasePrice){
		                            if(totalMinPlotPrice === 0 && totalMaxPlotPrice === 0)
		                            {
		                                totalMinPlotPrice = value.purchasePrice;
		                                totalMaxPlotPrice = value.purchasePrice;
		                            }

		                            if(totalMinPlotPrice > value.purchasePrice)
		                            {
		                                totalMinPlotPrice = value.purchasePrice;
		                            }

		                            if(totalMaxPlotPrice < value.purchasePrice)
		                            {
		                                totalMaxPlotPrice = value.purchasePrice;
		                            }
		                        }

								if(project?.purchasePriceAfterProfitOnPreparatoryMeasures && project?.areaTotal &&  value.area){
									totalPrice = totalPrice + ( value.area * (project?.purchasePriceAfterProfitOnPreparatoryMeasures / project?.areaTotal ));
		                        }
		                    }

		                    if(typeof d?.json?.mapGeoJson !== "undefined" )
		                    {
		                         for (let [key, value] of entries(d?.json?.mapGeoJson.features)) {
		                            if(value.geometry.type === "Polygon")
		                            {
		                                totalPlots = totalPlots+1;
		                            }

		                         }
		                    }


		                    if(typeof d?.json?.propertyData !== "undefined" ){
		                        for (let [key, value] of entries(d.json.propertyData)) {

		                            for (let [key1, value1] of entries(properties)) {
		                                if(typeof value1 !== "undefined" ) {
		                                    for (let [key2, value2] of entries(value1)) {
		                                        if(value?.propertyType?.includes(value2.name))
		                                        {
		                                            if(value2.units){
		                                                if(totalMinUnits === 0 && totalMaxUnits === 0)
		                                                {
		                                                    totalMinUnits = value2.units;
		                                                    totalMaxUnits = value2.units;
		                                                }

		                                                if(totalMinUnits > value2.units)
		                                                {
		                                                    totalMinUnits = value2.units;
		                                                }

		                                                if(totalMaxUnits < value2.units)
		                                                {
		                                                    totalMaxUnits = value2.units;
		                                                }
		                                            }
		                                            if(value2.size){
		                                                if(totalMinLivingArea === 0 && totalMaxLivingArea === 0)
		                                                {
		                                                    totalMinLivingArea = value2.size;
		                                                    totalMaxLivingArea = value2.size;
		                                                }

		                                                if(totalMinLivingArea > value2.size)
		                                                {
		                                                    totalMinLivingArea = value2.size;
		                                                }

		                                                if(totalMaxLivingArea < value2.size)
		                                                {
		                                                    totalMaxLivingArea = value2.size;
		                                                }
		                                            }
		                                        }
		                                    }
		                                }
		                            }
		                        }
		                    }

		                    return (
		                         <div className={"cardContainer"}>
			                         <div className={"card draftList"}>
			                             <a href={"/project/"+id+"/drafts/"+d.id}>
				                             <div className={"cardName"}>
				                                <IoIosArrowRoundForward/><span> {(d.name)? d.name : "-"  } {(d.published === true)? (<span style={{color:"#20976d"}}>(Homepage)</span>) :""}</span>
				                             </div>
				                             <div className={"cardDetails"}>
				                                <div>
				                                     <span>Stand: { date.toLocaleString("de-DE") }</span>
				                                </div>
				                                <div>
				                                    <RiLayoutMasonryLine title={"Parzellen"}/>
				                                    <span>{(totalPlots)? totalPlots+" Stk." : "0"  }</span>
				                                </div>

				                                 <div>
				                                     <RiBuilding2Line title={"Wohneinheiten"}/>
				                                     <span>{(totalMinUnits && totalMaxUnits)? totalMinUnits+" Stk. - "+totalMaxUnits +" Stk." : "0 Stk."  }</span>
				                                 </div>
				                                 <div>
				                                     <SlSizeFullscreen  title={"Grundstücksflächen"}/>
				                                     <span>{(totalMinPlotArea && totalMaxPlotArea)? totalMinPlotArea+" m² - "+totalMaxPlotArea+" m²" : "0 m²"  }</span>
				                                 </div>
				                                 <div>
				                                     <BiScreenshot title={"Grundstückspreise"}/>
				                                     <span>{(totalMinPlotPrice && totalMaxPlotPrice)? totalMinPlotPrice+" € - "+totalMaxPlotPrice+" €" : "0 €"  }</span>
				                                 </div>
				                                 <div>
				                                     <HiOutlineCurrencyEuro title={"Gesamt Verkaufspreis des Projektes"}/>
				                                     <span>{(totalPrice)? totalPrice.toFixed(2)+" €" : "0 €"  }</span>
				                                 </div>
				                             </div>
			                             </a>
			                             <h3>
											 <a  title={"Bebauungskonzept öffen"} href={"/project/"+id+"/drafts/"+d.id}>Öffnen</a>
		                                 </h3>
									</div>
								</div>

		                    )
		                })}

		             </div>

					<a className={"subButton"} href={"/project/"+id+"/drafts/create"}> Neues Projekt erstellen</a>
		         </div>
		    </div>
		);
		}


}
