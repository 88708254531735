import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFileOn} from "react-icons/ci";


export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente / Öffentliche Mittel / Sachsen-Anhalt", "/project/"+id+"/drafts/"+draftId+"/documents/category") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <p>
                    Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
                </p>
                <div className={"documentSplit"}>
                    <div className={"documentOverview document"}>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/IB-Datenschutzhinweise.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									IB-Datenschutzhinweise
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/IB-FD_Checkliste_Neubau_Ersterwerb.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Checkliste Neubau
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/IB-FD_Einkommenserklaerung_ekb1a.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Einkommenserklärung des Antragstellers
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/IB-FD_Einkommenserklaerung_ekb1b.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Einkommenserklärung der/des Haushaltsangehörigen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/IB-FD_Merkblatt.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Merkblatt: IB-Förderdarlehen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/SCHUFA_Selbstauskunft_AN-5-001.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									SCHUFA - Selbstauskunft
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/Selbsthilfe-Verpflichungserklaerung_Vordr_20201013.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Selbsthilfe - Verpflichungserklärung
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/SEPA_Lastschriftmandat2023.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									SEPA - Lastschriftmandat 2023
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/unterschriftskarte_LP_0-003.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Unterschriftskarte
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/sa/vollmacht_darlehen_VO-0-002.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Vollmacht Darlehen
								</div>
							</a>
						</div>
					 </div>
                 </div>
			 </div>
        </div>
    );
}

