import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFileOn} from "react-icons/ci";

export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente / Öffentliche Mittel /  Berlin", "/project/"+id+"/drafts/"+draftId+"/documents/category") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <p>
                    Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
                </p>
                <div className={"documentSplit"}>
                    <div className={"documentOverview document"}>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/b/anlage_selbstauskunft_r.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Anlage zur Selbstauskunft
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/b/esg-selbsterklaerung.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									ESG-Selbsterklärung* zu den Nachhaltigkeitsleitlinien
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/b/ibbfoerderergaenzungsdarlehen_d_vvi.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Vorvertragliche Informationen IBB Förderergänzungsdarlehen
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/b/if_finanzierungsplan_antrag_r.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Finanzierungsplan / Antrag
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/b/selbstauskunft_wohneigentuemer_r.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Selbstauskunft Wohneigentümer
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/b/wohneigentuemer_unterlagenanforderung_r.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Unterlagencheckliste für selbstnutzende Wohneigentümer
								</div>
							</a>
						</div>
					 </div>
                 </div>
			 </div>
        </div>
    );
}

