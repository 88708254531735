import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFileOn} from "react-icons/ci";

export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente / Öffentliche Mittel /  Brandenburg", "/project/"+id+"/drafts/"+draftId+"/documents/category") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <p>
                    Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
                </p>
                <div className={"documentSplit"}>
                    <div className={"documentOverview document"}>
	                    <div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/subview/bb/Brandenburg-Kredit-Energieeffizienter-Wohnungsbau-Antrag.4416.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
									ANTRAG auf einen Brandenburg-Kredit Energieeffizienter Wohnungsbau (BK-EW)
	                            </div>
	                        </a>
	                    </div>
						<div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/subview/bb/Brandenburg-Kredit-Energieeffizienter-Wohnungsbau-Kurzinformation.247168.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
									Kurzinformation	Wohnungsbau
	                            </div>
	                        </a>
	                    </div>
						<div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/subview/bb/Erklaerung-Politisch-exponierte-Person-PEP.105024.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
									Erläuterung zur Erklärung "Politisch exponierte Person" (PEP)
								</div>
	                        </a>
	                    </div>
						<div className={"documentElement"}>
	                        <a href={`${process.env.PUBLIC_URL}/formes/subview/bb/Flyer-Brandenburg-Kredite-fuer-die-Wohnungswirtschaft.102018.pdf`}>
	                            <div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
									Brandenburg-Kredite für die Wohnungswirtschaft
								</div>
	                        </a>
	                    </div>

					 </div>
                 </div>
			 </div>
        </div>
    );
}

