import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';


export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente: SPACEDATISTS", "/project/"+id+"/drafts/"+draftId+"/documents/") }
             <div id={"page-project-documents"} className={"wrapper-content"}>

					<iframe src={"https://form.jotform.com/230704139176353"}></iframe>
			 </div>
        </div>
    );
}