import React, {useEffect, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import { useNavigate } from 'react-router-dom';
import  {  Auth } from 'aws-amplify';
import {  Link } from 'react-router-dom';
import { IoTrashOutline } from "react-icons/io5";
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";

export default function Page(){

    const [list,setList] = useState(null)
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = React.useState({creationDateFrom: "01.01.2023", createDateTimeStamp:1672531200000});
    const [filterCounter, setFilterCounter] = React.useState(1);

    const handleFilterUpdate = (e) => {
        var newFilter = {...filter};
            newFilter[e.target.name] = e.target.value;
            setFilter(newFilter);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function getProjectsByFilter() {
        var filters = {...filter};
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        const user = await Auth.currentAuthenticatedUser();

        if(user?.attributes["custom:companyID"] !== null) {

            // add Scope
            filters.companyId = user?.attributes["custom:companyID"];
            filters.companyType = user?.attributes["custom:type"];
            filters.userId = user?.attributes["sub"];

            let dateStr = filters["creationDateFrom"];
            let date = new Date(dateStr.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'));
            filters.createDateTimeStamp =  Date.parse(date);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(filters),
                redirect: 'follow'
            };

            const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search/projects", requestOptions)
                .then(response => response.json())
                .then(response => {
                    setList(response?.rows);
                })
                .catch(error => console.log('error', error));
        }
    }

	useEffect(() => {
        getProjectsByFilter();
	}, []);

    console.log(list)


	return(
		<div>
            { navi((list !== null) ? "Grundstücke ("+list?.length+")" :"Grundstücke", "/") }

            <div className={"filter wrapper-content"}>

                <Button className={"filterButton"} variant="outlined" onClick={handleClickOpen}>
                    <Badge badgeContent={filterCounter} color="primary">
                        Filter
                    </Badge>
                </Button>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                            {"Filter wählen"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            <div className={"filterFieldBlock"}>
                                <div>
                                    <TextField
                                        required
                                        id="outlined secondary"
                                        label="Eingangsdatum ab"
                                        name="creationDateFrom"
                                        value={(typeof filter?.creationDateFrom !== "undefined") ? (filter?.creationDateFrom): ("")}
                                        onChange={(e)=>{handleFilterUpdate(e)}}
                                    />
                                </div>
                            </div>

                            <div className={"filterFieldBlock"}>
                                <div>
                                    <TextField
                                        required
                                        id="outlined secondary"
                                        label="Grundstücksgröße von"
                                        name="plotareaFrom"
                                        value={(typeof filter?.plotareaFrom !== "undefined") ? filter?.plotareaFrom: ""}
                                        onChange={(e)=>{handleFilterUpdate(e)}}
                                    />
                                </div>

                                <div>
                                    <TextField
                                        required
                                        id="outlined secondary"
                                        label="Grundstücksgröße bis"
                                        name="plotareaTo"
                                        value={(typeof filter?.plotareaTo !== "undefined") ? filter?.plotareaTo: ""}
                                        onChange={(e)=>{handleFilterUpdate(e)}}

                                    />
                                </div>
                            </div>

                            <div className={"filterFieldBlock"}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="source">Herkunft</InputLabel>
                                        <Select
                                            labelId="source"
                                            id="source"
                                            name="source"
                                            value={(typeof filter?.source !== "undefined") ? filter?.source: ""}
                                            onChange={(e)=>{handleFilterUpdate(e)}}

                                        >
                                            <MenuItem value={"Landingpage Website"}>Landingpage Website</MenuItem>
                                            <MenuItem value={"Immobilienportal"}>Immobilienportal</MenuItem>
                                            <MenuItem value={"Zuträger"}>Zuträger</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className={"filterFieldBlock"}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="buildability">Vermarktbarkeit</InputLabel>
                                        <Select
                                            labelId="buildability"
                                            id="buildability"
                                            name="buildability"
                                            value={(typeof filter?.buildability !== "undefined") ? filter?.buildability: ""}
                                            onChange={(e)=>{handleFilterUpdate(e)}}
                                        >
                                            <MenuItem value={"Sofort bebaubar mit Eigentümer-Erlaubnis"}>Sofort bebaubar mit Eigentümer-Erlaubnis</MenuItem>
                                            <MenuItem value={"Sofort bebaubar - noch kein Kontakt zu Eigentümer"}>Sofort bebaubar - noch kein Kontakt zu Eigentümer</MenuItem>
                                            <MenuItem value={"Nicht sofort bebaubar"}>Nicht sofort bebaubar</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className={"filterFieldBlock"}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="projectPhase">Projekt-Fortschritt</InputLabel>
                                        <Select
                                            labelId="projectPhase"
                                            id="projectPhase"
                                            name="projectPhase"
                                            value={(typeof filter?.projectPhase !== "undefined") ? filter?.projectPhase: ""}
                                            onChange={(e)=>{handleFilterUpdate(e)}}
                                        >
                                            <MenuItem value={"Aufstellung"}>Aufstellung</MenuItem>
                                            <MenuItem value={"Offenlegung"}>Offenlegung</MenuItem>
                                            <MenuItem value={"Satzung"}>Satzung</MenuItem>
                                            <MenuItem value={"Vorplanung"}>Vorplanung</MenuItem>
                                            <MenuItem value={"Finale Planung"}>Finale Planung</MenuItem>
                                            <MenuItem value={"Vermarktung"}>Vermarktung</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className={"filterFieldBlock"}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="projectCalculationType">Projekkalkulation</InputLabel>
                                        <Select
                                            labelId="projectCalculationType"
                                            id="projectCalculationType"
                                            name="projectCalculationType"
                                            value={(typeof filter?.projectCalculationType !== "undefined") ? filter?.projectCalculationType: ""}
                                            onChange={(e)=>{handleFilterUpdate(e)}}
                                        >
                                            <MenuItem value={"Genossenschaft"}>Genossenschaft</MenuItem>
                                            <MenuItem value={"Individual"}>Individual</MenuItem>
                                            <MenuItem value={"Typenhaus"}>Typenhaus</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>



                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Schließen</Button>
                        <Button onClick={() =>{setFilter({}); getProjectsByFilter(); setFilterCounter(0);}}>Zurücksetzten</Button>
                        <Button onClick={() =>{getProjectsByFilter(); setFilterCounter(Object.keys(filter).length); handleClose();}}
                                autoFocus>
                            Suchen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div id={"page-project-overview"} className={"wrapper-content"}>
	               {( list !== null ) ? (<div>
                       {( list !== [] && list.length > 0) ? (
                            <div className={"plotList"}>
                                { list?.map((entity) => (
                                    <div className={"plotListEntity"}>
                                        <div className={"title"}>
                                            <div>
                                                <Link to={'/project/'+entity?.id}  >
                                                    <b>{entity?.name}</b>
                                                    <span>{entity?.addressstreet} - {entity?.addresszipcode} {entity?.addresstown}</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <a title={"Projekt löschen"} className={"delete"} href={"/delete?projectId="+entity?.id+"&type=project&name="+entity?.name}><IoTrashOutline /></a>
                                            </div>
                                        </div>
                                        <Link to={'/project/'+entity?.id} >
                                            <div className={"details"}>

                                                {(entity?.firstname) ? (
                                                    <div>
                                                        <span>Betreuer</span>
                                                        <span>{entity?.firstname} {entity?.lastname}</span>
                                                    </div>
                                                ):("")}

                                                <div>
                                                    <span>Ges. Fläche</span>
                                                    <span>{(entity?.areatotal) ? (entity?.areatotal)+" m²": "0.00 m²"}</span>
                                                </div>
                                                <div>
                                                    <span>Netto Fläche</span>
                                                    <span>{(entity?.areanet) ? (entity?.areanet)+" m²": "0.00 m²"}</span>
                                                </div>
                                                <div>
                                                    <span>Preis pro m²</span>
                                                    <span>{(entity?.purchasepriceafterprojectengineeringprofit && entity?.areaTotal ) ? (Number(entity?.purchasepriceafterprojectengineeringprofit) / Number(entity?.areatotal)).toFixed(2)+" €" : "0.00 m²"} </span>
                                                </div>
                                                <div>
                                                    <span>Bodenrichtwert</span>
                                                    <span>{(entity?.standardlandvalue) ? (entity?.standardlandvalue)+" €/m²" : "0.00 €/m²" }</span>
                                                </div>
                                            </div>
                                            <div className={"price"}>
                                                <span>{(entity?.purchasepriceafterprojectengineeringprofit) ? (entity?.purchasepriceafterprojectengineeringprofit)+" €": "0.00 €"}</span>
                                                <span>Ges. Verkaufspreis</span>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{textAlign:"center", margin:"100px auto"}}>
                                <h3>Keine Grundstücken vorhanden</h3>
                                <a href="/projects/create">Jetzt Grundstück <u>erstellen</u></a>
                            </div>
                       )}
                   </div>) : ("")}
            </div>
        </div>
    );

}
