import React, { useEffect,  useState } from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';

export default function Page(){

    const [access,setAccess] = useState(null);
	const {token} = useParams();
	const navigate = useNavigate();

	async function checkAccessToken() {

		var headers = new Headers();
			headers.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'GET',
          headers: headers,
          redirect: 'follow'
        };

        const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/share/"+token, requestOptions)
                         .catch(error => console.log('error', error));

        const res = await response.json();
		setAccess(res?.[0]);
    }


	useEffect(() => {
		checkAccessToken();
	}, []);

	// render
	if(access?.id) {
		if(access.accessLevel === "basicAccessProject") {
			navigate('/share/project/'+access.entityId+'/34b5a7ae-e80b-4ec2-a99f-b70e479508df/editor/projectAddress');
		}
		else if(access.accessLevel === "fullAccessProject") {
            navigate('/share/project/'+access.entityId+'/bb3588ad-8e20-4d37-b875-f8d73eb55c6a/editor/projectAddress');
        }
        else
        {
            return (<></>);
        }
	}
}
