import React, {useEffect, useState} from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {FileContext} from "../../context/FileContext";
import OrderUploadBidHandler from "./components/files/orderUploadHandler";

export default function Overview(){

	let {id,draftId} = useParams();
	const [user,setUser] = useState(null);


	return(
        <div>
	        { navi("Dokumente / Meine Unterlagen", "/project/"+id+"/drafts/"+draftId+"/documents/") }



	         <div id={"page-project-documents"} className={"wrapper-content"}>
	             <p>
					 Hinterlegen Sie hier Unterlagen und Dokumente zu Ihrem Vorhaben. Sämtliche Dokumente werden im Kontext des Projektes zu erfassen.
                 </p>
	             <div className={"myDocuments"}>

					 <FileContext.Provider className={"wrapper"} value={{id,draftId}}>
						 <OrderUploadBidHandler/>
					 </FileContext.Provider>
				 </div>
			 </div>
		 </div>
    );
}

