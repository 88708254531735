import React, {useState, useContext, useEffect} from 'react';
import * as turf from "@turf/turf";
import properties from "../../../configs/propertiesData.json";
import {DraftContext} from "../../../context/DraftContext";
import { signAction } from 'onoffice';
import { RxUpdate } from "react-icons/rx";


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function createHmac2(token, secret, data) {
    return signAction(data, token, secret);
}

function generateID() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
}

function blobToFile(blob, fileName) {
    // Convert blob to File object
    const file = new File([blob], fileName);
    return file;
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(blob);
    });
}

function isEmpty(obj) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

export default function Page() {

    const {draft,draftId,project,user} = useContext(DraftContext);
    const [secret, setSecret] = useState(null);
    const [stopLoop, setStopLoop] = useState(false);
    const [OnOfficeList, setOnOfficeList] = useState({});
    const [token, setToken] = useState(null);
    const [currentSpin, setCurrentSpin] = useState({});
    const [buttonLabel, setButtonLabel] = useState("Objektvarianten erstellen");

    Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() + (h*60*60*1000));
        return this;
    }

    const GermanDateTime = ( date ) => {
        const formattedDate = new Date(date).addHours(2).toLocaleString('de-DE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        });

        return (<div>Stand: {formattedDate} Uhr </div>);
    };

    const handleOnOfficeGetLink = (id) => {
        if(typeof token !== "undefined") {

            var headers1 = new Headers();
            headers1.append("Content-Type", "application/json");


            var action =
                {
                    actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:get",
                    identifier: "x",
                    parameters: {
                        "recordId": id
                    },
                    resourceid: "estate",
                    resourcetype: "getlink"
                };

            var requestOptions = {
                method: 'POST',
                headers: headers1,
                body: JSON.stringify({
                    "token"     : token,
                    "request"   : {
                        "actions" : [
                            createHmac2(token, secret, action)
                        ]
                    }
                }),
                redirect: 'follow',



            };

            fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
                .then(res => res.json())
                .then(response => window.open(response?.response?.results?.[0]?.data?.records?.[0]?.["elements"]?.["url"]))
                .catch(error => console.error('Error: ', error));

        }

    }

    const handleOnOfficeProjectPublishing = () => {



        for (var a in draft?.json?.mapGeoJson?.features) {
            var point = draft?.json?.mapGeoJson?.features[a];
            var pointData = draft?.json?.mapGeoJson?.features[a]?.geometry?.coordinates;


            if (point?.geometry?.type === 'Point') {


                setButtonLabel("Übertragung läuft ...");
                for (var b in draft?.json?.mapGeoJson?.features) {
                    var polygon = draft?.json?.mapGeoJson?.features[b];
                    var polygonData = draft?.json?.mapGeoJson?.features[b]?.geometry?.coordinates;


                    if (polygon?.geometry?.type === 'Polygon'  && typeof pointData !== "undefined" && typeof polygonData !== "undefined") {


                        var a = turf.point(pointData);
                        var b = turf.polygon(polygonData);

                        if(turf.booleanPointInPolygon(a, b)) {
                            for (var i in draft?.json?.propertyData?.[point.id]["propertyType"]) {


                                for(var p in properties["properties"]) {

                                    if(draft?.json?.propertyData?.[point.id]["propertyType"][i] === properties["properties"][p]["name"]){

                                        var headers1 = new Headers();
                                        headers1.append("Content-Type", "application/json");

                                        var price = 0;

                                        if(typeof draft?.json?.incidentalBuildingCosts  !== "undefined") {
                                            price =  price + Number(draft?.json?.incidentalBuildingCosts );
                                        }

                                        if(typeof properties["properties"][p]?.price !== "undefined" && typeof properties["properties"][p]?.units !== "undefined") {


                                            var housePrice = Number(properties["properties"][p]?.price) / Number(properties["properties"][p]?.units);


                                            if(typeof draft?.json?.regionalPremiumPercent !== "undefined" && typeof draft?.json?.regionalPremiumPercentMin !== "undefined" && typeof draft?.json?.regionalPremiumPercentMax !== "undefined") {

                                                var regionalPremium =  (housePrice * (Number(draft?.json?.regionalPremiumPercent) / 100))
                                                console.log("###############################");
                                                console.log("Baunebenkosten: "+ Number(draft?.json?.incidentalBuildingCosts ));
                                                console.log("-------------------------");
                                                console.log("Hauspreise nach liste: "+housePrice);
                                                console.log("Regionalzuschlag in %: "+draft?.json?.regionalPremiumPercent);
                                                console.log("Regionalzuschlag in €: "+regionalPremium);
                                                console.log("Regionalzuschlag(Min) in €: "+ Number(draft?.json?.regionalPremiumPercentMin));
                                                console.log("Regionalzuschlag(Max) in €: "+ Number(draft?.json?.regionalPremiumPercentMax));
                                                console.log("-------------------------");

                                                if(regionalPremium > draft?.json?.regionalPremiumPercentMax) {
                                                    regionalPremium =  Number(draft?.json?.regionalPremiumPercentMax);
                                                } else if(regionalPremium < draft?.json?.regionalPremiumPercentMin) {
                                                    regionalPremium =  Number(draft?.json?.regionalPremiumPercentMin);
                                                }

                                                console.log("-------------------------");
                                                console.log("Neuer Regionalzuschlag in €: "+ regionalPremium);
                                                console.log("NNeuer Hauspreis in €: "+ (housePrice+regionalPremium));
                                            }



                                            price =  price + (housePrice+regionalPremium);


                                            if(typeof draft?.json?.mapData?.[polygon.id]?.purchasePrice !== "undefined") {
                                                price =  price + Number(draft?.json?.mapData?.[polygon.id]?.purchasePrice);
                                            }



                                            console.log("-------------------------");
                                            console.log("Grundstückspreis: "+ Number(draft?.json?.mapData?.[polygon.id]?.purchasePrice));
                                            console.log("Grundstück + Haus in € : "+ price);
                                            console.log("-------------------------");
                                            console.log("Gesamtpreis: "+ price);
                                            console.log("###############################\n\n");

                                        }

                                        if(typeof project?.json?.addressStreet !== "undefined" ) {
                                            var housenumber = project?.json?.addressStreet.split(" ");
                                        } else {
                                            var housenumber = null
                                        }


                                        var action = {
                                            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:create",
                                            identifier: "",
                                            parameters: {
                                                "data": {
                                                    "objektnr_extern": draftId.slice(0, 9)+""+generateID(),
                                                    "objektart": "haus",
                                                    "nutzungsart": "wohnen",
                                                    "vermarktungsart": "kauf",
                                                    "objekttyp": "einfamilienhaus",
                                                    "strasse": (housenumber !== null) ? project?.json?.addressStreet.substr(0,project?.json?.addressStreet.lastIndexOf(" ") + 1) :project?.json?.addressStreet,
                                                    "hausnummer": (housenumber !== null) ? housenumber[housenumber.length - 1] :"",
                                                    "plz": project?.json?.addressZipCode,
                                                    "ort": project?.json?.addressTown,
                                                    "land": "DEU",
                                                    "InterneBemerkung": project?.name+" - "+draft?.json?.mapData?.[polygon.id]?.name+" - "+properties["properties"][p]?.name,
                                                    "grundstuecksflaeche": Number(draft?.json?.mapData?.[polygon.id]?.area),
                                                    "wohnflaeche": Number(properties["properties"][p]?.size),
                                                    "anzahl_zimmer": Number(properties["properties"][p]?.rooms),
                                                    "objektbeschreibung": properties["properties"][p]?.description+"\n\n"+draft?.json?.conceptDescription+"\n\n",
                                                    "ausstatt_beschr": properties["properties"][p]?.equipmentDescription,
                                                    "sonstige_angaben": properties["properties"][p]?.freeDescription,
                                                    "etagen_zahl": Number(properties["properties"][p]?.floors),
                                                    "lage": draft?.json?.locationDescription,
                                                    "objekttitel": draft?.name+" - "+properties["properties"][p]?.name,
                                                    "importFreitexte": draftId+"-"+polygon.id +"-"+ properties["properties"][p]?.id,
                                                    "kaufpreis": price
                                                }
                                            },
                                            resourceid: "estate",
                                            resourcetype: "estate"
                                        };


                                        var requestOptions = {
                                            method: 'POST',
                                            headers: headers1,
                                            body: JSON.stringify({
                                                "token"     : token,
                                                "request"   : {
                                                    "actions" : [
                                                        createHmac2(token, secret, action)
                                                    ]
                                                }
                                            }),
                                            redirect: 'follow',
                                        };


                                        handleOnOfficeUploadEstate (requestOptions,p, properties["properties"][p]);

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        sleep(5000).then(() => {
            setButtonLabel("Objektvarianten erstellen");
            getOnOfficeList();
        });
    }

    const handleOnOfficeProjectUpdate = async (id, currentSpinner) => {

        var headers1 = new Headers();
        headers1.append("Content-Type", "application/json");

        var action = {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            identifier: "",
            parameters: {
                "data": [
                    "importFreitexte"
                ]
            },
            resourceid: id,
            resourcetype: "estate"
        };

        var requestOptions = {
            method: 'POST',
            headers: headers1,
            body: JSON.stringify({
                "token"     : token,
                "request"   : {
                    "actions" : [
                        createHmac2(token, secret, action)
                    ]
                }
            }),
            redirect: 'follow',
        };

        fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
            .then((res) => res.json())
            .then((res) =>  {
                var importFreitexte = res?.response?.results?.[0]?.data?.records?.[0]?.elements?.importFreitexte;
                if(typeof res?.response?.results?.[0]?.data?.records?.[0]?.elements?.importFreitexte !== undefined) {
                    for (var a in draft?.json?.mapGeoJson?.features) {
                        var point = draft?.json?.mapGeoJson?.features[a];
                        var pointData = draft?.json?.mapGeoJson?.features[a]?.geometry?.coordinates;

                        if (point?.geometry?.type === 'Point') {

                            for (var b in draft?.json?.mapGeoJson?.features) {
                                var polygon = draft?.json?.mapGeoJson?.features[b];
                                var polygonData = draft?.json?.mapGeoJson?.features[b]?.geometry?.coordinates;

                                if (polygon?.geometry?.type === 'Polygon'  && typeof pointData !== "undefined" && typeof polygonData !== "undefined") {

                                    var a = turf.point(pointData);
                                    var b = turf.polygon(polygonData);

                                    if(turf.booleanPointInPolygon(a, b)) {
                                        for (var i in draft?.json?.propertyData?.[point.id]["propertyType"]) {
                                            for(var p in properties["properties"]) {
                                                if(draft?.json?.propertyData?.[point.id]["propertyType"][i] === properties["properties"][p]["name"]){

                                                    var headers1 = new Headers();
                                                    headers1.append("Content-Type", "application/json");

                                                    var price = 0;

                                                    if(typeof draft?.json?.incidentalBuildingCosts  !== "undefined") {
                                                        price =  price + Number(draft?.json?.incidentalBuildingCosts );
                                                    }

                                                    if(typeof properties["properties"][p]?.price !== "undefined" && typeof properties["properties"][p]?.units !== "undefined") {


                                                        var housePrice = Number(properties["properties"][p]?.price) / Number(properties["properties"][p]?.units);


                                                        if(typeof draft?.json?.regionalPremiumPercent !== "undefined" && typeof draft?.json?.regionalPremiumPercentMin !== "undefined" && typeof draft?.json?.regionalPremiumPercentMax !== "undefined") {

                                                            var regionalPremium =  (housePrice * (Number(draft?.json?.regionalPremiumPercent) / 100))
                                                            console.log("##############  "+ properties["properties"][p]?.name +"  #################");
                                                            console.log("Baunebenkosten: "+ Number(draft?.json?.incidentalBuildingCosts ));
                                                            console.log("-------------------------");
                                                            console.log("Hauspreise nach liste: "+housePrice);
                                                            console.log("Regionalzuschlag in %: "+draft?.json?.regionalPremiumPercent);
                                                            console.log("Regionalzuschlag in €: "+regionalPremium);
                                                            console.log("Regionalzuschlag(Min) in €: "+ Number(draft?.json?.regionalPremiumPercentMin));
                                                            console.log("Regionalzuschlag(Max) in €: "+ Number(draft?.json?.regionalPremiumPercentMax));
                                                            console.log("-------------------------");

                                                            if(regionalPremium > draft?.json?.regionalPremiumPercentMax) {
                                                                regionalPremium =  Number(draft?.json?.regionalPremiumPercentMax);
                                                            } else if(regionalPremium < draft?.json?.regionalPremiumPercentMin) {
                                                                regionalPremium =  Number(draft?.json?.regionalPremiumPercentMin);
                                                            }

                                                            console.log("-------------------------");
                                                            console.log("Neuer Regionalzuschlag in €: "+ regionalPremium);
                                                            console.log("NNeuer Hauspreis in €: "+ (housePrice+regionalPremium));
                                                        }



                                                        price =  price + (housePrice+regionalPremium);


                                                        if(typeof draft?.json?.mapData?.[polygon.id]?.purchasePrice !== "undefined") {
                                                            price =  price + Number(draft?.json?.mapData?.[polygon.id]?.purchasePrice);
                                                        }



                                                        console.log("-------------------------");
                                                        console.log("Grundstückspreis: "+ Number(draft?.json?.mapData?.[polygon.id]?.purchasePrice));
                                                        console.log("Grundstück + Haus in € : "+ price);
                                                        console.log("-------------------------");
                                                        console.log("Gesamtpreis: "+ price);
                                                        console.log("###############################\n\n");

                                                    }

                                                    if(typeof project?.json?.addressStreet !== "undefined" ) {
                                                        var housenumber = project?.json?.addressStreet.split(" ");
                                                    } else {
                                                        var housenumber = null
                                                    }

                                                    if(typeof project?.json?.addressStreet !== "undefined" ) {
                                                        var housenumber = project?.json?.addressStreet.split(" ");
                                                    } else {
                                                        var housenumber = null
                                                    }

                                                    if(importFreitexte === draftId+"-"+polygon.id +"-"+ properties["properties"][p]?.id) {
                                                        var action = {
                                                            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:modify",
                                                            identifier: "",
                                                            parameters: {
                                                                "data": {
                                                                    "objektart": "haus",
                                                                    "nutzungsart": "wohnen",
                                                                    "vermarktungsart": "kauf",
                                                                    "objekttyp": "einfamilienhaus",
                                                                    "strasse": (housenumber !== null) ? project?.json?.addressStreet.substr(0,project?.json?.addressStreet.lastIndexOf(" ") + 1) :project?.json?.addressStreet,
                                                                    "hausnummer": (housenumber !== null) ? housenumber[housenumber.length - 1] :"",
                                                                    "plz": project?.json?.addressZipCode,
                                                                    "ort": project?.json?.addressTown,
                                                                    "land": "DEU",
                                                                    "InterneBemerkung": project?.name+" - "+draft?.json?.mapData?.[polygon.id]?.name+" - "+properties["properties"][p]?.name,
                                                                    "grundstuecksflaeche": Number(draft?.json?.mapData?.[polygon.id]?.area),
                                                                    "wohnflaeche": Number(properties["properties"][p]?.size),
                                                                    "anzahl_zimmer": Number(properties["properties"][p]?.rooms),
                                                                    "objektbeschreibung": properties["properties"][p]?.description+"\n\n"+draft?.json?.conceptDescription+"\n\n",
                                                                    "ausstatt_beschr": properties["properties"][p]?.equipmentDescription,
                                                                    "sonstige_angaben": properties["properties"][p]?.freeDescription,
                                                                    "etagen_zahl": Number(properties["properties"][p]?.floors),
                                                                    "lage": draft?.json?.locationDescription,
                                                                    "objekttitel": draft?.name+" - "+properties["properties"][p]?.name,
                                                                    "importFreitexte": draftId+"-"+polygon.id +"-"+ properties["properties"][p]?.id,
                                                                    "befeuerung":["luftwp"],
                                                                    "baujahr":"2024",
                                                                    "zustand":"erstbezug",
                                                                    "heizungsart":["fussboden"],
                                                                    "kaufpreis": price
                                                                }
                                                            },
                                                            resourceid: id,
                                                            resourcetype: "estate"
                                                        };

                                                        console.log(action);

                                                        var requestOptions = {
                                                            method: 'POST',
                                                            headers: headers1,
                                                            body: JSON.stringify({
                                                                "token"     : token,
                                                                "request"   : {
                                                                    "actions" : [
                                                                        createHmac2(token, secret, action)
                                                                    ]
                                                                }
                                                            }),
                                                            redirect: 'follow',
                                                        };
                                                        fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
                                                            .then(res => res.json())
                                                            .then(res => console.log(res))
                                                            .then(res => {
                                                                currentSpinner[id]["spinning"] = false;
                                                                setOnOfficeList(currentSpinner);
                                                                console.log("-----------");
                                                                console.log(currentSpinner);
                                                            })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return true;
            })

    }

    const handleOnOfficeProjectUpdateAll = async (id) => {

        var spinner = {};
        var currentSpinner = {...OnOfficeList}

        if(typeof id !== "undefined") {
            console.log("--- one ---")
            currentSpinner[id]["spinning"] = true;
            setOnOfficeList(currentSpinner);

            handleOnOfficeProjectUpdate(id,currentSpinner);


            sleep(300).then(() => {

                getOnOfficeList()
            });


        } else {

            for (var estate of Object.keys(OnOfficeList)) {
                currentSpinner[OnOfficeList?.[estate]?.id]["spinning"] = true;
                setOnOfficeList(currentSpinner);

                handleOnOfficeProjectUpdate(OnOfficeList?.[estate]?.id,currentSpinner);
            }

            sleep(1000).then(() => {
                console.log("33")
                getOnOfficeList()
            });

        }



    }

    const handleOnOfficeUploadEstate = async (requestOptions, ook, tmpProps) => {

        fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
            .then(res => res.json())
            .then(response => {

                var url = "";
                if(window.location.hostname === "localhost") {
                    url = "http://"+window.location.hostname+":"+window.location.port+"/"+process.env.PUBLIC_URL;
                } else {
                    url = "https://"+window.location.hostname+"/"+process.env.PUBLIC_URL;
                }

                console.log("----------> Title Bild");
                console.log("#4##->"+url+properties["properties"][ook]?.image);
                var k = 1;
                var estateId = response?.response?.results[0]?.data?.records?.[0]?.id;
                var filename = tmpProps?.image.slice(tmpProps?.image.lastIndexOf('/') + 1)

                handleOnOfficeUploadImages("Titelbild",estateId,url+tmpProps?.image,filename,k,0);
                k++


                sleep(500).then(() => {

                    console.log("----------> Bilder");
                    if(tmpProps?.imageGallery) {
                        for (var q = 0; q < tmpProps?.imageGallery.length; q++) {
                            var filename1 = tmpProps?.imageGallery?.[q]?.image.slice(tmpProps?.imageGallery?.[q]?.image.lastIndexOf('/') + 1);
                            handleOnOfficeUploadImages("Foto", estateId, url+tmpProps?.imageGallery?.[q]?.image,  filename1, k, 0);
                            k++;
                        }
                    }

                    console.log("----------> Grundrisse");
                    if(tmpProps?.floorPlan) {
                        for (var q = 0; q < tmpProps?.floorPlan.length; q++) {
                            var filename2 = tmpProps?.floorPlan?.[q]?.image.slice(tmpProps?.floorPlan?.[q]?.image.lastIndexOf('/') + 1);
                            handleOnOfficeUploadImages("Grundriss", estateId, url+tmpProps?.floorPlan?.[q]?.image,  filename2, k, 0);
                            k++;
                        }
                    }
                });




            })
            .catch(error => console.error('Error: ', error));


    }

    const handleOnOfficeUploadImages = async (category,estateId,url, fileName,position) => {


        const imageFile = await fetch(url)
            .then((response) => response.blob())
            .then((pictures) =>  {

                var headers1 = new Headers();
                headers1.append("Content-Type", "application/json");


                var reader = new FileReader();
                reader.readAsDataURL(pictures);
                reader.onloadend = function() {
                    var base64data = reader.result;

                    var action = {
                        actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:do",
                        identifier: "",
                        parameters: {
                            "data": base64data.substr(base64data.indexOf(",") + 1)
                        },
                        resourceid: null,
                        resourcetype: "uploadfile"
                    };



                    var requestOptions = {
                        method: 'POST',
                        headers: headers1,
                        body: JSON.stringify({
                            "token"     : token,
                            "request"   : {
                                "actions" : [
                                    createHmac2(token, secret, action)
                                ]
                            }
                        }),
                        redirect: 'follow',
                    };

                    fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
                        .then(res => res.json())
                        .then(response => {

                            var headers1 = new Headers();
                            headers1.append("Content-Type", "application/json");

                            var action = [];
                            action.push(
                                createHmac2(token, secret, {
                                    actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:do",
                                    resourceid: null,
                                    resourcetype: "uploadfile",
                                    identifier: "",
                                    parameters: {
                                        module: "estate",
                                        position: position,
                                        freetext: "",
                                        tmpUploadId: response?.response?.results[0]?.data?.records?.[0]?.elements?.tmpUploadId,
                                        title: fileName,
                                        file: fileName,
                                        category: "external",
                                        Art: category,
                                        setDefaultPublicationRights: true,
                                        relatedRecordId: estateId
                                    }
                                })
                            );





                            var requestOptions = {
                                method: 'POST',
                                headers: headers1,
                                body: JSON.stringify({
                                    "token"     : token,
                                    "request"   : {
                                        "actions" : action
                                    }
                                }),
                                redirect: 'follow',
                            };


                            fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
                                .then(res => res.json())
                                .then(response => console.log(response));
                        });

                }

            });

    };

    const handleOnOfficeUploadLinks = async (result,  linkUrl, linkName, type,  token) => {

        if(token && linkName && linkUrl) {
            var myHeaders = new Headers();
            myHeaders.append("cognitoToken", token);
            myHeaders.append("Content-Type", "application/json");

            var json = JSON.stringify({
                "albumAssignments": [
                    {
                        "albumName": "homepage",
                        "categories": [
                            String(type)
                        ]
                    },
                    {
                        "albumName": "portal",
                        "categories": [
                            String(type)
                        ]
                    }
                ],
                "link": String(linkUrl),
                "title": String(linkName)
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: json,
                redirect: 'follow'
            };

            fetch("https://api.production.cloudios.OnOffice-prod.cloud/multimedia-service/items/schemas/house_purchase/entities/"+result?.entityIds?.[0]+"/link", requestOptions)
                .then(data => data.json())
                .catch(error => console.log('error', error));
        }


    };

    async function deleteOnOfficeEntity(id) {

        if(typeof token !== "undefined") {

            var headers1 = new Headers();
            headers1.append("Content-Type", "application/json");


            var action =
                {
                    actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:delete",
                    identifier: "",
                    parameters: {},
                    resourceid: id,
                    resourcetype:"estate"
                };



            var requestOptions = {
                method: 'POST',
                headers: headers1,
                body: JSON.stringify({
                    "token"     : token,
                    "request"   : {
                        "actions" : [
                            createHmac2(token, secret, action)
                        ]
                    }
                }),
                redirect: 'follow',
            };


            fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
                .then(res => res.json())
                .then(response => console.log(response))
                .catch(error => console.error('Error: ', error));

        }

        getOnOfficeList();
    }

    // user
    async function getOnOfficeList() {
        if (typeof token !== "undefined") {
            const headline = [];
            const headers1 = new Headers();
            headers1.append("Content-Type", "application/json");

            const action = {
                actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:get",
                identifier: "x",
                parameters: {
                    input: draftId.slice(0, 9),
                },
                resourceid: "estate",
                resourcetype: "search",
            };

            const requestOptions = {
                method: 'POST',
                headers: headers1,
                body: JSON.stringify({
                    token: token,
                    request: {
                        actions: [createHmac2(token, secret, action)],
                    },
                }),
                redirect: 'follow',
            };

            try {
                const res = await fetch(
                    (process.env.REACT_APP_MODE === 'PROD'
                        ? process.env.REACT_APP_API_URL_PRODUCTION
                        : process.env.REACT_APP_API_URL_DEVELOPMENT) + "/onoffice/api",
                    requestOptions
                );
                const response = await res.json();

                console.log("--- Response 1 ---");
                console.log(response);

                const fetchPromises = response?.response?.results?.[0]?.data?.records.map(async (b) => {
                    const headers1 = new Headers();
                    headers1.append("Content-Type", "application/json");

                    const action1 = {
                        actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
                        identifier: "",
                        parameters: {
                            data: [
                                "importFreitexte",
                                "Id",
                                "status",
                                "geaendert_am",
                                "wohnflaeche",
                                "kaufpreis",
                                "anzahl_zimmer",
                            ],
                        },
                        resourceid: b?.id,
                        resourcetype: "estate",
                    };

                    const requestOptions1 = {
                        method: 'POST',
                        headers: headers1,
                        body: JSON.stringify({
                            token: token,
                            request: {
                                actions: [createHmac2(token, secret, action1)],
                            },
                        }),
                        redirect: 'follow',
                    };

                    try {
                        const res = await fetch(
                            (process.env.REACT_APP_MODE === 'PROD'
                                ? process.env.REACT_APP_API_URL_PRODUCTION
                                : process.env.REACT_APP_API_URL_DEVELOPMENT) + "/onoffice/api",
                            requestOptions1
                        );
                        const result = await res.json();

                        console.log("--- Response 2 ---");
                        console.log(res);

                        const propertyId = result?.response?.results?.[0]?.data?.records?.[0]?.elements?.importFreitexte.slice(70, 106);
                        const plotId = result?.response?.results?.[0]?.data?.records?.[0]?.elements?.importFreitexte.slice(37, 69);

                        let type = "";
                        let title = "";

                        if (typeof draft?.json?.mapData?.[plotId] !== "undefined") {
                            type = draft?.json?.mapData?.[plotId]?.name;
                        }

                        for (const a of properties?.properties) {
                            if (a?.id === propertyId) {
                                title = a?.name;
                            }
                        }

                        headline[result?.response?.results?.[0]?.data?.records?.[0]?.elements?.Id] = {
                            id: result?.response?.results?.[0]?.data?.records?.[0]?.elements?.Id,
                            title: project?.name + " - " + type + " - " + title,
                            details: result?.response?.results?.[0]?.data?.records?.[0]?.elements,
                        };

                    } catch (error) {
                        console.error('Error:', error);
                    }
                });

                await Promise.all(fetchPromises);

                setOnOfficeList(headline);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }


    useEffect(() => {
        setToken(user?.["attributes"]?.["custom:onOfficeToken"]);
        setSecret(user?.["attributes"]?.["custom:onOfficeSecretToken"]);
    }, [draft]);

    if(isEmpty() === true && secret !== null && project && stopLoop === false) {
        getOnOfficeList();
        setStopLoop(true);
    }

    return  (
        <div>
            {(typeof user?.["attributes"]?.["custom:onOfficeToken"] !== "undefined" && typeof user?.["attributes"]?.["custom:onOfficeSecretToken"] !== "undefined") ? (
                <div className={"wrapper-content box"}>
                    <div className="data-container">
                        <div>
                            <h3>
                                <span>onOffice Sync</span>
                                <div>
                                    {(isEmpty(OnOfficeList) === false) ? ( <a type="submit" style={{marginRight:"10px"}} onClick={() => { handleOnOfficeProjectUpdateAll() }}>Alle Aktualisieren</a> ):("") }
                                    <a type="submit" onClick={() => { handleOnOfficeProjectPublishing(user?.["attributes"]?.["custom:onOfficeToken"], user?.["attributes"]?.["custom:onOfficeSecretToken"]) }}>{buttonLabel}</a>
                                </div>
                            </h3>
                            <p>Die folgenden Einstellungen werden für die Veröffentlichenung des Web-Exposes benötigt.</p>
                        </div>
                        <br/>
                        <br/>

                        <div class="card OnOfficeList">
                            { Object.keys(OnOfficeList)?.map((key ) => (
                                <div>
                                    <a target="_blank"  style={{width:"100%",display:"block"}} onClick={() => {handleOnOfficeGetLink(OnOfficeList?.[key]?.id,OnOfficeList)}}>
                                        <div className="cardName">
                                            <h5>{OnOfficeList?.[key]?.title}  ({OnOfficeList?.[key]?.id}) </h5>
                                            <div className={"flowfactCardDetails"}>

                                                {GermanDateTime(OnOfficeList?.[key]?.details?.geaendert_am)}
                                                <div>
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <title>Zimmer</title>
                                                        <path d="M19 14h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h3v-2H5v-2H3v3a1 1 0 0 0 1 1zM19 4a1 1 0 0 0-1-1h-3v2h2v2h2V4zM5 5h2V3H4a1 1 0 0 0-1 1v3h2V5zM3 9h2v4H3zm14 0h2v3h-2zM9 3h4v2H9zm0 14h3v2H9z">
                                                        </path>
                                                    </svg>
                                                    <span>{OnOfficeList?.[key]?.details?.anzahl_zimmer}</span>
                                                </div>
                                                <div>
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <title>Grundstücksfläche</title>
                                                        <path d="M685.904 383.184l275.264-273.572-.896 155.072c-.289 12.928 9.967 24.176 22.912 23.888l16.416-.016c12.944-.304 23.648-8 23.92-20.928l.672-231.008c0-.223-.88-.399-.88-.623l1.264-11.712c.128-6.496-1.391-12.288-5.584-16.431-4.143-4.176-9.951-6.72-16.4-6.544l-11.696.272c-.223 0-.399.08-.64.113L760.77.687c-12.928.288-23.632 10.976-23.92 23.92l-.032 16.417c1.967 15.248 13.952 24.16 26.88 23.872l151.248.4L640.657 337.92c-12.496 12.496-12.496 32.752 0 45.264 12.496 12.48 32.752 12.48 45.247 0zM339.088 640.812L63.825 913.532l.88-154.224c.304-12.944-9.969-24.192-22.897-23.904l-17.423.032c-12.96.32-23.649 8-23.921 20.944l-.672 231.008c0 .224.88.367.88.623l-1.264 11.68c-.144 6.496 1.376 12.32 5.584 16.433 4.128 4.192 9.952 6.72 16.384 6.56l11.712-.288c.223 0 .383-.096.64-.096l230.495 1.008c12.928-.32 23.617-11.009 23.92-23.936l.032-16.432c-1.967-15.216-13.952-24.16-26.88-23.872l-151.247-.4L384.32 686.076c12.496-12.497 12.496-32.752 0-45.248s-32.737-12.512-45.233-.016zm685.122 346.56l-.672-231.01c-.288-12.944-10.992-20.624-23.92-20.944l-16.416-.032c-12.944-.289-23.184 10.975-22.912 23.903l.896 155.072-275.28-273.552c-12.496-12.496-32.752-12.496-45.248 0s-12.496 32.752 0 45.248L914.93 958.649l-151.232.4c-12.928-.288-24.912 8.657-26.88 23.872l.032 16.432c.304 12.944 11.008 23.633 23.92 23.936l229.504-1.007c.24 0 .416.095.64.095l11.696.288c6.448.16 12.272-2.368 16.4-6.56 4.193-4.128 5.696-9.936 5.584-16.432l-1.263-11.68c0-.255.88-.399.88-.622zM110.049 65.321l151.264-.397c12.928.288 24.912-8.64 26.88-23.873l-.032-16.431C287.84 11.677 277.15.972 264.24.7l-230.512.992c-.256-.032-.416-.112-.64-.112l-11.712-.273C14.945 1.132 9.105 3.676 4.992 7.851.784 11.995-.735 17.787-.592 24.283L.672 35.995c0 .223-.88.384-.88.624l.672 231.008c.288 12.928 10.977 20.624 23.921 20.928l17.424.015c12.928.288 23.183-10.96 22.895-23.888l-.88-154.224 275.264 272.72c12.48 12.497 32.753 12.497 45.25 0s12.496-32.768 0-45.264z">
                                                        </path>
                                                    </svg>
                                                    <span>{OnOfficeList?.[key]?.details?.wohnflaeche} m²</span>
                                                </div>
                                                <div>
                                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <title>Verkaufspreis</title>
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z">
                                                        </path>
                                                    </svg>
                                                    <span> {OnOfficeList?.[key]?.details?.kaufpreis} €</span>
                                                </div>
                                            </div>

                                        </div>
                                    </a>
                                    <div>
                                        {<div style={{cursor:"pointer"}} title={"Objekt aktualisieren"} onClick={() => {handleOnOfficeProjectUpdateAll(OnOfficeList?.[key]?.id)}}  >

                                            {((OnOfficeList?.[key]?.["spinning"] === true)) ? (<RxUpdate className={"spin-animation"}/>) : (<RxUpdate/>)}


                                        </div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) :("") }
        </div>
    );

}

