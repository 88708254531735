import React, {useState} from 'react'
import './App.scss';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import Amplify, {I18n,Auth} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import navi from './component/navigation/Component-navigation';
import Overview from './pages/overview/Page-overview';

import Notification from './pages/notification/Page-overview';

import Help from './pages/help/Page-overview'
import DeleteEntity from './pages/Page-delete';

import Land from './pages/overview/Page-land';
import PotentialsOverview from './pages/potentials/Page-overview';
import PotentialsPotentialMap from './pages/potentials/Page-potentials-map';

import DevelopmentsOverview from './pages/developments/Page-overview';
import DevelopmentsCommunityMap from './pages/developments/Page-map';

import Project from './pages/project/Page-overview';
import ProjectCreate from './pages/project/Page-create';
import ProjectCreateByPotential from './pages/project/Page-create-by-potential';
import ProjectProfile from './pages/project/Page-profile';
import ProjectEditor from './pages/project/Page-editor';
import ProjectDraftsEditor from './pages/project/Page-drafts-editor';
import ProjectDraftsCreate from './pages/project/Page-drafts-create';

import DocumentsOverview from './pages/project/Page-documents-overview';
import DocumentsMyDocuments from './pages/project/Page-documents-myDocuments';
import DocumentsSubView from './pages/project/Page-documents-subView';
import DocumentsSubViewB from './pages/project/Page-documents-subView-b';
import DocumentsSubViewBB from './pages/project/Page-documents-subView-bb';
import DocumentsSubViewNW from './pages/project/Page-documents-subView-nw';
import DocumentsSubViewRH from './pages/project/Page-documents-subView-rh';
import DocumentsSubViewSA from './pages/project/Page-documents-subView-sa';
import DocumentsSubViewNI from './pages/project/Page-documents-subView-ni';
import DocumentsServiceGeno from './pages/project/Page-documents-serviceGeno';
import DocumentsFinancing from './pages/project/Page-documents-financing';
import DocumentsSpacedatists from './pages/project/Page-documents-spacedatists';
import DocumentsEditor from './pages/project/Page-documents-editor';

import Users from './pages/users/Page-overview';
import UserProfile from './pages/user/Page-profile';
import UserSettings from './pages/user/Page-setting';
import UserIntegrations from './pages/user/Page-integrations';
import UserHouseDatabase from './pages/user/Page-houseDatabase';
import UserTipper from './pages/user/Page-tipper';

import SharedProjectEditor from './share/project/components/Page-editor';
import SharedProject from './share/project/Page-share';
import AmplifyI18n from "amplify-i18n"

import { formFields } from './component/form/formFields';
import {SignUp} from "./component/form/SignUp";


Amplify.configure(awsExports);
AmplifyI18n.configure()
I18n.setLanguage("de")
Auth.configure(awsExports);


function App() {

    const [type, setType] = useState('---');
    const components  = {
        SignUp: {
            FormFields()  {
                return <SignUp/>;
            }
        },
    };



    if( window.location.pathname.split("/")[1] === "share") {
        return (
            <div className="App">
                  <BrowserRouter >
                      <div className={"wrapper"}>
                          <Routes>
                              <Route path='share/:token' element={<SharedProject/>}></Route>
                              <Route path='share/project/:id/:flowtype/editor/:json' element={<SharedProjectEditor/>}></Route>
                          </Routes>
                      </div>
                  </BrowserRouter>
              </div>
        );

    }
    else
    {
        return (
			<Authenticator  formFields={formFields} components={components} >
		        {({ signOut, user }) => (
					<div className="App">
						<BrowserRouter >
						    <div className={"wrapper"}>

					            <Routes>
					                <Route path='/' element={<Overview/>} ></Route>
					                <Route path='/plotLand' element={<Land/>} ></Route>
					                <Route path='/help' element={<Help/>} ></Route>
					                <Route path='/delete' element={<DeleteEntity/>}></Route>
					                <Route path='/notification' element={<Notification/>} ></Route>
									<Route path='/cadastral/:district' element={<DevelopmentsCommunityMap/>} ></Route>
									<Route path='/cadastral' element={<DevelopmentsOverview/>} ></Route>
									<Route path='/projects/create' element={<ProjectCreate/>} ></Route>
									<Route path='/projects/create/:district/:geoId' element={<ProjectCreateByPotential/>}></Route>
					                <Route path='/project/:id/:flowtype/editor/:json' element={<ProjectEditor/>}></Route>
					                <Route path='/project/:id/drafts/create' element={<ProjectDraftsCreate/>}></Route>
					                <Route path='/project/:id/drafts/:draftId' element={<ProjectDraftsEditor/>} ></Route>
					                <Route path='/project/:id/drafts/:draftId/documents/' element={<DocumentsOverview/>}></Route>
					                <Route path='/project/:id/drafts/:draftId/documents/myDocuments' element={<DocumentsMyDocuments/>}></Route>
					                <Route path='/project/:id/drafts/:draftId/documents/spacedatists' element={<DocumentsSpacedatists/>}></Route>
	                                <Route path='/project/:id/drafts/:draftId/documents/finacing/' element={<DocumentsFinancing/>}></Route>
                                    {(user?.attributes?.["custom:serviceMember"] === "1") ? (<Route path='/project/:id/drafts/:draftId/documents/service/' element={<DocumentsServiceGeno/>}></Route>):("")}
                                    <Route path='/project/:id/drafts/:draftId/documents/category/b' element={<DocumentsSubViewB/>}></Route>
                                    <Route path='/project/:id/drafts/:draftId/documents/category/bb' element={<DocumentsSubViewBB/>}></Route>
                                    <Route path='/project/:id/drafts/:draftId/documents/category/nw' element={<DocumentsSubViewNW/>}></Route>
                                    <Route path='/project/:id/drafts/:draftId/documents/category/ni' element={<DocumentsSubViewNI/>}></Route>
                                    <Route path='/project/:id/drafts/:draftId/documents/category/sa' element={<DocumentsSubViewSA/>}></Route>
                                    <Route path='/project/:id/drafts/:draftId/documents/category/rh' element={<DocumentsSubViewRH/>}></Route>
	                                <Route path='/project/:id/drafts/:draftId/documents/category/' element={<DocumentsSubView/>}></Route>
	                                <Route path='/project/:id/drafts/:draftId/documents/category/:file' element={<DocumentsEditor/>}></Route>
	                                <Route path='/project/:id' element={<ProjectProfile/>} ></Route>
	                                <Route path='/projects/' element={<Project/>} ></Route>
					                <Route path='/users' element={<Users/>} ></Route>
					                <Route path='/user/' element={<UserProfile/>} ></Route>
					                <Route path='/user/settings' element={<UserSettings/>} ></Route>
					                <Route path='/user/integrations' element={<UserIntegrations/>} ></Route>
					                <Route path='/user/houseDatabase' element={<UserHouseDatabase/>} ></Route>
					                <Route path='/user/tipper' element={<UserTipper/>} ></Route>
					                <Route path='*' />
					            </Routes>
						    </div>
						</BrowserRouter>
					</div>
				)}
		    </Authenticator>
		);
	}
}

export default App;
