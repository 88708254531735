import React, {useEffect, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import { Auth } from 'aws-amplify';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

export default function Page() {

    const [list,setList] = useState(null);
	const [user,setUser] = useState(null);
	const navigate = useNavigate();

	const columns: GridColDef[] = [
	  {
	    field: 'name',
	    headerName: 'Bezeichnung',
	    width: 300,
	    renderCell: (cellValues) => {return (cellValues.row.name) ? cellValues.row.name : "-" },
	  },
	  {
	    field: 'governmentCommunitiesDistrictsId',
	    headerName: 'Gemeinde Code',
	    width: 300,
	    valueGetter: (params: GridValueGetterParams) => `${ (params.row.governmentCommunitiesDistrictsId) ? params.row.governmentCommunitiesDistrictsId : 0} `,
	  }
	];

	// add company ID
    async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
    }

	async function getPotentials(sortBy,sortType) {

		if(!sortBy && !sortType) {
			sortBy = "name";
			sortType = "asc";

		}

		var headers = new Headers();
			headers.append("Content-Type", "application/json");


		if(user?.attributes["custom:type"] == "admin") {

            var raw = JSON.stringify(
            {
                schema: "geoCommunitiesDistricts",
                select: "\"id\",\"name\",\"governmentCommunitiesDistrictsId\"",
                condition: [
                    {
                        type: "like",
                        field: "governmentCommunitiesDistrictsId",
                        values: 0
                    }
                ],
                sortBy: sortBy,
                sortType: sortType
            });

            var requestOptions = {
              method: 'POST',
              headers: headers,
              body: raw,
              redirect: 'follow'
            };


            const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
                             .catch(error => console.log('error', error));
            const responseText = await response.json();

            setList(responseText.rows);

        }
        else {
			if(user?.attributes["custom:geoFilter"])
	        {
	            var raw = JSON.stringify({
	                schema: "geoCommunitiesDistricts",
	                select: "\"id\", \"name\", \"governmentCommunitiesDistrictsId\"",
	                condition: [
	                    {
	                        type: "like",
	                        field: "governmentCommunitiesDistrictsId",
	                        values: user?.attributes["custom:geoFilter"].split(",")
	                    }
	                ],
                    sortBy: sortBy,
                    sortType: sortType
	            });

	            var requestOptions = {
	                method: 'POST',
	                headers: headers,
	                body: raw,
	                redirect: 'follow'
	            };

	            const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
	                             .catch(error => console.log('error', error));
	            const responseText = await response.json();

	            setList(responseText.rows);

	        }
        }
    }

	// init
	useEffect(() => {
		getUser();
	}, []);


	if( user !== null && list == null) {
		getPotentials();
	}


	return(
		<div>
            { navi("Bauleitpläne", "/") }
            <div id={"page-potential-overview"} className={"wrapper-content"}>
                <div className={"potentialList"} style={{ height: 900, padding:'21px',  }}>
	                {(list !== null)? (
						<DataGrid
						    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
						    rows={list}
						    onRowClick={(params: GridRowParams ) => {navigate('/cadastral/'+params.row.governmentCommunitiesDistrictsId );} }
						    columns={columns}
						    checkboxSelection={false}
						    disableRowSelectionOnClick={false}
						    disableVirtualization={true}
						    editMode={false}
						/>
	                ):("")}
                </div>
            </div>
        </div>
    );
}
