import React, {useEffect, useState } from 'react';
import navi from "../../component/navigation/Component-navigation";
import imageWelcome from "../../assets/image/welcome.jpg";
import {Link} from "react-router-dom";
import {TfiAgenda, TfiPlus, TfiSearch} from "react-icons/tfi";
export default function Page(){
	// render
	return(
        <div>
            { navi("Bauleitpläne", "/",true) }
            <div id={"page-overview-overview"} className={"wrapper-content"}>

                <iframe style={{width:"100%", height: "900px", border:"0", scrollBehavior:"auto" }} src={"https://xplanung24.de/open-data/bebauungsplaene/bp?iframe=1"}></iframe>

            </div>
        </div>
    );
}
