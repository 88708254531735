import React,{useState, useEffect} from 'react';
import {useParams} from 'react-router';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {HiOutlineTrash} from "react-icons/hi";
import {MdContentCopy, MdOutlineOpenInNew} from "react-icons/md";
import amplify, { API, Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import {BiPencil, BiCircle} from "react-icons/bi";
import {AiOutlineDelete} from "react-icons/ai";

export default function Page(){

	const {id} = useParams();
    const [access, setAccess] = useState(null);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState("https://main.d1at6m68ssvfy6.amplifyapp.com/share/");
    //const [url, setUrl] = useState("http://localhost:3000/");
    const [list, setList] = useState(null);
    const [drafts, setDrafts] = useState(null);
    const [user, setUser] = useState(Auth.currentAuthenticatedUser());
    const [open, setOpen] = useState(false);

	// add company ID
	async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
    }

	// get API Data
    async function getAccesses() {
            var requestOptions = {
              method: 'GET',
              redirect: 'follow'
            };
            var data = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/accesses/"+id, requestOptions)
              .then(response => { return response.json() })
              .catch(error => console.log('error', error));
    		setList(data);
        }

	async function getDrafts() {
            var headers = new Headers();
                headers.append("Content-Type", "application/json");


            var raw = JSON.stringify(
                {
                    schema: "projectsDrafts",
                    condition: [
                        {
                            type: "eq",
                            field: "projectsId",
                            values: [
                                id
                            ]
                        }
                    ]
                });

            var requestOptions = {
              method: 'POST',
              headers: headers,
              body: raw,
              redirect: 'follow'
            };

            const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
                             .catch(error => console.log('error', error));

            const data = await response.json();
            setDrafts(data.rows);
        }

    const handleSaving = () => {
        var newAccess = {...access}
        if(typeof newAccess.type !== "undefined" && typeof newAccess.name !== "undefined" && (typeof newAccess.accessLevel !== "undefined" || newAccess.type === "partner")) {

			var headers = new Headers();
                headers.append("Content-Type", "application/json");

            if(user?.attributes["custom:companyID"] !== null ) {

                // add companyIds
                newAccess.id = uuidv4();
                newAccess.entityId = id;
                newAccess.companyId = user?.attributes["custom:companyID"];
                newAccess.type = access?.["type"];
                newAccess.accessLevel = (newAccess.type === "partner") ? "fullAccessProject" : newAccess.accessLevel;

                var raw = JSON.stringify(newAccess);
                var requestOptions = {
                  method: 'POST',
                  headers: headers,
                  body: raw,
                  redirect: 'follow'
                };

               fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/share", requestOptions)
                   .then((response) => response.json())
                   .then((response) => {

                       if(typeof response?.result !== "undefined" ) {
                           console.log("user not found");
                           setError("Leider wurde zur angegebenen E-Mail-Adresse kein passender  Home & Family Benutzer gefunden.")

                       } else {
                           console.log("user found");
                           var temp = list;
                           temp.push(newAccess);

                           setList(temp);
                           setError(null);
                           setOpen(false);

                       }
                   })
                   .catch(error => console.log('error', error));






            }
        }
    };

    const handelChanges = (e) => {
		var newAccess = {...access}
			newAccess[e.target.name] = e.target.value;
		setAccess(newAccess);
    };

	const handleClickOpen = () => {  setOpen(true); };
    const handleClose = () => { setOpen(false); };

	useEffect(() => {
        getUser();
        getAccesses();
        getDrafts();
    },[]);

	return (
		<div className={"cardContainer"}>
        		 <h3>
        			<span>Zugriffe verwalten</span>
        			<a className={"active"} onClick={() => {setOpen(true);}}>Erstellen</a>
        		 </h3>

        	    {(list?.length > 0)?  (
        	         <div >
	                     {list?.map(function(d, idx) {
						return(
							<div className={"card accessListElement "}>
								<div className={"cardName"}>
									<h5>
										{d.name} &nbsp;
										({(d.type === "partner") ? ("H&F User") :""}
										{(d.type === "owner") ? ("Eigentümer") :""}
                                        {(d.type === "community") ? ("Gemeinde") :""}
                                        {(d.type === "tipper") ? ("Zuträger") :""})

									</h5>
								</div>


								<div>
									{(d.accessLevel === "fullAccessProject") ? ("Vollzugriff auf Projekt") :""}
									{(d.accessLevel === "basicAccessProject") ? ("Teilzugriff auf Projekt") :""}
									{(d.accessLevel === "previewAccessExpose") ? ("Exposè Vorschau") :""}
								</div>


                                {(d.type !== "partner") ? (
                                    <>
                                        {(d.draftsId && d.accessLevel === "previewAccessExpose") ?
                                        (
                                            <div className={"token"}>
                                                <a target={"_blank"} title={"Öffenen"} href={"https://main.d1at6m68ssvfy6.amplifyapp.com/"+user?.attributes?.["custom:companyID"]+"?access="+d?.id}><MdOutlineOpenInNew/></a>
                                                <div>{"https://main.d1at6m68ssvfy6.amplifyapp.com/"+user?.attributes?.["custom:companyID"]+"?access="+d?.id}</div>
                                                <a target={"_blank"} title={"Kopieren"}  onClick={() => {navigator.clipboard.writeText("https://main.d1at6m68ssvfy6.amplifyapp.com/"+user?.attributes?.["custom:companyID"]+"?access="+d?.id)}}><MdContentCopy/></a>
                                            </div>
                                        ) : (
                                            <div className={"token"}>
                                                <a target={"_blank"} title={"Öffenen"} href={url+"share/"+d.id}><MdOutlineOpenInNew/></a>
                                                <div>{url+"share/"+d.id}</div>
                                                <a target={"_blank"} title={"Kopieren"}  onClick={() => {navigator.clipboard.writeText(url+"share/"+d.id)}}><MdContentCopy/></a>
                                            </div>
                                        )}
                                    </>
                                ) : ("")}
								<div>
									<a  className={"delete"} title={"Zugriff löschen"} href={"/delete?projectId="+id+"&draftId="+d?.id+"&type=sharedAccess&name="+d.name}><AiOutlineDelete/></a>
								</div>

		                    </div>
						);
	                    })}
	                 </div>) : (<div className={"notFound"}>Keine Zugriffsrechte vergebenen</div>)}

        	 <div>
        		   <Dialog
        			 open={open}
        			 onClose={handleClose}
        			 aria-labelledby="alert-dialog-title"
        			 aria-describedby="alert-dialog-description"
        		   >
        			 <DialogTitle id="alert-dialog-title">
        			   {"Zugriff gewähren"}
        			 </DialogTitle>
        			 <DialogContent>
        			   <DialogContentText className={"dialogDescription"}>
        					<div>
        					    Sie haben nun die Möglichkeit Dritten Zugriff auf Ihre Projekt zu gewähren. Wählen Sie hierzu die Art des Zugriffs , die Bezeichnung des Zugangs und die Art der Anzeige.
        					</div>
        					<div className={"dialogForm"}>


                                <FormControl >
                                    <InputLabel id="type">Empfänger</InputLabel>
                                    <Select
                                        id={"type"}
                                        name={"type"}
                                        value={(access?.type) ? access?.type : ""}
                                        style={{width: "100%"}}
                                        onChange={handelChanges}
                                    >
                                        <MenuItem key={"partner"} value={"partner"}>H&F User</MenuItem>
                                        <MenuItem key={"tipper"} value={"tipper"}>Zuträger</MenuItem>
                                        <MenuItem key={"owner"} value={"owner"}>Eigentümer</MenuItem>
                                        <MenuItem key={"community"} value={"community"}>Gemeinde</MenuItem>
                                    </Select>
                                </FormControl>

                                {(access !== null) ? (
                                <>
                                    <TextField
                                        name={"name"}
                                        label="Zugangsname"
                                        type = "text"
                                        onChange={handelChanges}
                                        value={(access?.name) ? access?.name : ""}
                                    />

                                    {(access?.type === "partner") ? (
                                        <div>
                                            <TextField
                                                name={"userEMail"}
                                                label="E-Mail des H&F Users eingeben"
                                                type = "text"
                                                onChange={handelChanges}
                                                value={(access?.userEMail) ? access?.userEMail : ""}
                                            />
                                        </div>
                                    ):""}
                                    {(access?.type !== null && access?.type !== "partner") ? (
                                        <>
                                            <FormControl >
                                                <InputLabel id="accessLevel">Zugriffsrecht</InputLabel>
                                                <Select
                                                    id={"accessLevel"}
                                                    name={"accessLevel"}
                                                    value={(access?.accessLevel) ? access?.accessLevel : ""}
                                                    style={{width: "100%"}}
                                                    onChange={handelChanges}
                                                >
                                                    <MenuItem key={"fullAccessProject"} value={"fullAccessProject"}>Vollzugriff auf das Projekt</MenuItem>
                                                    <MenuItem key={"basicAccessProject"} value={"basicAccessProject"}>Teilzugriff auf das Projekt</MenuItem>
                                                    <MenuItem key={"previewAccessExpose"} value={"previewAccessExpose"}>Vorschau des Projekt Exposés</MenuItem>
                                                </Select>
                                            </FormControl>

                                            { (access?.accessLevel === "previewAccessExpose") ? (
                                                <FormControl >
                                                    <InputLabel id="accessDraftId">Bebauungskonzept wählen</InputLabel>
                                                    <Select
                                                        id={"draftsId"}
                                                        name={"draftsId"}
                                                        value={(access?.draftsId) ? access?.draftsId : ""}
                                                        style={{width: "100%"}}
                                                        onChange={handelChanges}
                                                    >
                                                        { drafts?.map(function(d, idx) {
                                                            return (<MenuItem key={d?.id} value={d?.id}>{d?.name}</MenuItem>)
                                                        })}
                                                    </Select>
                                                </FormControl>) : ("")
                                            }
                                        </>
                                    ):("")}
                                </>
                                ):("")}
        					</div>

                           {(error !== null) ? (<div style={{background:"#ffbcbc", color:"#930000", borderRadius:"8px", padding:"16px"}}>{error}</div>):("")}

        			   </DialogContentText>
        			 </DialogContent>
        			 <DialogActions>
        			   <Button onClick={handleClose}>Schließen</Button>
        			   <Button onClick={() => {handleSaving()}} autoFocus>
        				 Zugang erstellen
        			   </Button>
        			 </DialogActions>
        		   </Dialog>
        	 </div>
        </div>
	);

}

/*
	<MenuItem key={"fullAccessProject"} value={"fullAccessProject"}>Vollzugriff auf das Projekt</MenuItem>
    <MenuItem key={"basicAccessProject"} value={"basicAccessProject"}>Teilzugriff auf das Projekt</MenuItem>

*/