import React, {useContext, useEffect, useState} from 'react';
import { Storage } from 'aws-amplify';
import { useDropzone } from 'react-dropzone';
import { VscCloudUpload } from "react-icons/vsc";
import { SlTrash } from "react-icons/sl";

const S3UploaderWithDropzone = (name,path,orderEdit) => {
    const [uploading, setUploading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);


    const onDrop = async (acceptedFiles, path) => {
        if (acceptedFiles.length === 0) return;



        try {
            // Upload file to S3
            await Promise.all(

                acceptedFiles.map(async (file) => {
                    const key = `${path}/${file.name}`;

                    await Storage.put(key, file, {
                        progressCallback: (progress) => {
                            setUploadProgress(Math.round((progress.loaded / progress.total) * 100));
                        },
                    });
                })
            );
            setUploading(true);


        } catch (error) {
            console.error('Error uploading file: ', error);
            alert('Error uploading file. Please try again.');
        } finally {
            setUploading(false);
            setUploadProgress(0);
            fetchUploadedFiles(path);
        }
    };
    const getFormatedFileSize = (bytes) => {
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " bytes"; }
        else if (bytes == 1)          { bytes = bytes + " byte"; }
        else                          { bytes = "0 bytes"; }

        return bytes;
    }
    const fetchUploadedFiles = async () => {
        await Storage.list(path)
            .then(( results ) => {
                setUploadedFiles(results)})
            .catch((err) => console.log(err));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, path),
    });

    const handleDeleteFile = async (keyToDelete) => {
        try {
            // Delete the file from S3 bucket
            await Storage.remove(keyToDelete);

            // After successful deletion, update the file list
            fetchUploadedFiles();
        } catch (error) {
            console.error('Error deleting file:', error);
            // Handle error as needed
        }
    };

    const generateDownloadUrl = async (s3Key) => {
        try {

            const name = s3Key.split("/").pop();
            const url = await Storage.get(s3Key)
                .then((url) => {
                    const link = document.createElement('a');
                    // Set the href attribute to the file URL
                    link.href = url;
                    // Set the download attribute to the desired filename
                    link.download = name;
                    // Programmatically trigger a click event on the anchor element
                    link.click();
                });

        } catch (error) {
            console.error('Error generating download URL:', error);
        }
    };

    useEffect(() => {
        fetchUploadedFiles();
    }, []);

    return (
        <div>

            <div className={"flex sm:gap-4 "}>
                <div className={(orderEdit === true) ? "w-6/12": "w-full"}>
                    <b className={"block font-normal text-gray-900 mr-2   pb-4"}>{name}</b>
                    <>
                        {( uploadedFiles?.length > 0 ) ? (
                            <ul role="list" className="divide-y  divide-white/5">
                                {uploadedFiles.map((files) => (
                                    <li key={files.key.split("/").pop()} className="relative flex items-center space-x-4 py-4">
                                        <div className="min-w-0 flex-auto">
                                            <div className="flex items-center gap-x-3">
                                                <h2 className="min-w-0 text-sm font-normal leading-6">
                                                    <a onClick={() => generateDownloadUrl(files.key)} className="cursor-pointer text-gray-600  flex gap-x-2">
                                                        <span className="truncate">{files.key.split("/").pop()}</span>

                                                    </a>
                                                </h2>
                                            </div>

                                        </div>
                                        <div className="flex" >
                                            <div
                                                className={'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-gray-500 ring-inset'}
                                            >
                                                {getFormatedFileSize(files.size)}
                                            </div>
                                            <button title={"Dokument löschen"} onClick={() => handleDeleteFile(files.key)} className="cursor-pointer flex gap-x-2">
                                                <SlTrash  className="h-5 w-5 flex-none text-gray-600" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ): ("-")}

                    </>
                </div>


                <div {...getRootProps()}  className={(orderEdit === true) ? "w-6/12 mt-2 flex justify-center rounded-lg border-2 border-dashed border-gray-500  px-6 py-10": "hidden w-6/12 mt-2  flex justify-center rounded-lg border-2 border-dashed border-gray-50 px-6 py-10 "}>
                    <div className="text-center self-center ">

                        <VscCloudUpload className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
                        <div className="mt-4 flex text-sm leading-6 text-gray-900">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >

                                {(uploading) ? (<b>Fortschritt: {uploadProgress}%</b>) : ( <><b>Dateien per Klick</b><span className="pl-1 font-normal"> oder per drag & drop hochladen</span></>)}
                                <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                            </label>

                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, PDF, ZIP up to 10MB</p>
                    </div>
                </div>
            </div>


        </div>

    );
};

export default S3UploaderWithDropzone;
