import React, { useEffect, useRef, useState } from 'react';
import {useParams} from 'react-router';
import navi from '../../component/navigation/Component-navigation';
import mapboxgl from 'mapbox-gl';
import {MapboxStyleDefinition,  MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import 'mapbox-gl/dist/mapbox-gl.css';
import "mapbox-gl-style-switcher/styles.css";
import { Amplify } from 'aws-amplify';



export default function Page(){
	const ref = useRef(null);
    const [map, setMap] = useState(null);

    const [plots, setPlots] = useState(null);
    const [insides, setInsides] = useState(null);
    const [insideId, setInsideId] = useState(null);
    let {community,district,id} = useParams();
	const mapKey = process.env.REACT_APP_MAPS_KEY;
 mapboxgl.accessToken = mapKey;

	// map styles
	const styles: MapboxStyleDefinition[] = [
        {
            title: "Satellit",
            uri:"mapbox://styles/mapbox/satellite-v9"
        },
        {
            title: "Straße",
            uri:"mapbox://styles/nebuto/cl3ahdmto000715mnmlssp94j"
        }
    ];

	// map definition
	const handelMapLoad = (e, p) => {

		if (typeof ref !== "undefined" && typeof map !== "undefined" && ref?.current && !map && e && p) {

			 let mapPlots = [];
			 let mapCenter = [];
			 let g = p[0];

		     const map = new mapboxgl.Map({
		         displayControlsDefault: false,
		         container: ref?.current,
		         style: "mapbox://styles/nebuto/cldya34fd00bj01mg8wzsmuji",
		         center: g?.mapCenter,
                 zoom: 12,
                 maxZoom: 19,
		    });

			for (let i = 0; i < g?.mapGeoJson.length; i++) {
				var k = {};
				let s = g.mapGeoJson[i];

				 k["type"]       = "Feature";
				 k["properties"] = JSON.parse(s.properties);
				 k["geometry"]   = s.geometry;
                mapPlots.push(k);
            }

		    map.on('style.load', () => {

	             // Add a data source containing GeoJSON data.
	             map.addSource('mapLayer', {
	                'type': 'geojson',
		            'data': {
                            "type": "FeatureCollection",
                            "name": "mapLayer",
                            "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                            "features": mapPlots
                            }
	             });

	             // Add a new layer to visualize the polygon.
	             map.addLayer({
		             'id': 'mapLayer',
		             'type': 'fill',
		             'source': 'mapLayer', // reference the data source
		             'layout': {},
		             'paint': {
		             'fill-color': '#1c3254', // blue color fill
		             'fill-opacity': 0.1
		             }
		         });

		         // Add a black outline around the polygon.
		         map.addLayer({
		             'id': 'outline',
		             'type': 'line',
		             'source': 'mapLayer',
		             'layout': {},
		             'paint': {
		             'line-color': '#1c3254',
		             'line-width': 3
		             }
		         });


               map.on('click', 'mapLayer', (e) => {

					var main = e.features[0].properties;
					var data = getInsides(main?.parcelId);
						data.then(function(result) {
						    var entity = result.rows[0];
						    var html =      "<div class='mapCard'>";
							    var html = html+"<div><b>Eintrag: "+entity.name+"</b></div>";
								var html = html+"<div class='list'>";
	                                 if(main.creationDate){
	                                    var d = new Date(entity.date);
	                                    var html = html+"<div><p>Datum:      </p><p>"+d.getDate()  + "." + (d.getMonth()+1) + "." + d.getFullYear()+"</p></div>";
	                                 }
	                                 var html = html+"<div><p>Änderungszeichen:   </p><p>"+entity.sourceId+"</p></div>";


	                                 if(entity.json.urls){
	                                    var url = entity.json.urls;
	                                    if(url.scans) {var html = html+"<div><p>Bauleitplaunng:</p><a target='_blank' href='"+url.scans+"'>Zur Website</a></div>";}
	                                    if(url.environment) {var html = html+"<div><p>Umweldbewertung:</p><a target='_blank' href='"+url.environment+"'>Zur Website</a></div>";}
	                                    if(url.justification) {var html = html+"<div><p>Begründung:</p><a target='_blank' href='"+url.justification+"'>Zur Website</a></div>";}
	                                    if(url.other) {var html = html+"<div><p>Weiter Informationen:</p><a target='_blank' href='"+url.other+"'>Zur Website</a></div>";}
	                                 }
	                            var html = html+"</div>";
                            var html = html+"</div>";
						    new mapboxgl.Popup()
                            .setLngLat(e.lngLat)
                            .setHTML(html)
                            .addTo(map);
                            });
						});


                // Change the cursor to a pointer when
                // the mouse is over the states layer.
                map.on('mouseenter', 'mapLayer', () => {
                map.getCanvas().style.cursor = 'pointer';
                });

                // Change the cursor back to a pointer
                // when it leaves the states layer.
                map.on('mouseleave', 'mapLayer', () => {
                map.getCanvas().style.cursor = '';
                });
             });

			map.on('load', () => {
		         map.addControl(new MapboxStyleSwitcherControl(styles, 'Dark','Satellit'));
	        });


		    setMap(map);
		}
		return true;
    }

	async function getPotentials() {

		var headers = new Headers();
			headers.append("Content-Type", "application/json");

		var raw = JSON.stringify(
		{
            schema: "geoCommunitiesDistricts",
            condition: [
                {
                    type: "like",
                    field: "governmentCommunitiesDistrictsId",
                    values: [
                        district
                    ]
                }
            ]
        });

		var requestOptions = {
		  method: 'POST',
		  headers: headers,
		  body: raw,
		  redirect: 'follow'
		};


		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
						 .catch(error => console.log('error', error));

	    const responseText = await response.json();

	    setPlots(responseText.rows);
    }

    async function getInsides(a) {

		var headers = new Headers();
			headers.append("Content-Type", "application/json");

		var raw = JSON.stringify(
		{
            schema: "geoCommunitiesDistrictsInsides",
            condition: [
                {
                    type: "eq",
                    field: "parcelId",
                    values: [a]
                }
            ]
        });

		var requestOptions = {
		  method: 'POST',
		  headers: headers,
		  body: raw,
		  redirect: 'follow'
		};

		const response = await fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/search", requestOptions)
						 .catch(error => console.log('error', error));

	    const responseText = await response.json();

	    return responseText;
    }

	// init dataload
    useEffect(() => {
        if(district)
        {
			getPotentials();
        }
    }, [district]);


	// init map
	if(plots !== null) {
	    handelMapLoad(district, plots);
	    district = plots?.[0].name;
	}

	// render
	return(
		<div>
            { navi("Bauleitpläne / "+district, "/cadastral") }
            <div id={"page-potentials-map"} >
                <div className="map-container" ref={ref}  ></div>
            </div>
        </div>
    );
}
