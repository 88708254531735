import React, {useEffect,useState} from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import navi from '../../component/navigation/Component-navigation';
import imageWelcome from '../../assets/image/welcome.jpg';
import { TfiPlus, TfiSearch, TfiAgenda} from "react-icons/tfi";
import {v4} from 'uuid';
export default function Page(){


	const [user,setUser] = useState(null);

	// add company ID
	async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        if(user?.attributes["custom:companyID"] == null) { await Auth.updateUserAttributes(user, {'custom:companyID': v4(), 'custom:type': "user"}); }
        setUser(user);
    }


	useEffect(() => {
		getUser();
	},[]);



    return(
		<div>
			{ navi("Übersicht", null) }
			<div id={"page-overview-overview"} className={"wrapper-content"}>

				<div className={"splashScreen"}>
					<div>
						<img src={imageWelcome} />
						<h3>Willkommen bei Home & Family</h3>
						<p>
							Ihrer Plattform für Moderne Projektentwicklung in der Stadt und auf dem Land.<br/>Erstellen Sie jetzt ein neues Projekt.
						</p>
					</div>
					<div className={"primaryActions"}>
						{(user?.attributes?.["custom:type"] !== "supporter") ? (
							<>
								<Link className={"secondary"} to={"/plotLand"}><TfiAgenda/>Bauleitpläne</Link>
							</>
						) : ""}
						<Link className={"secondary"} to={"/projects"}><TfiSearch/>Zur Grundstücksübersicht</Link>
						<Link className={"highlighed"} to={"/projects/create"}><TfiPlus/>Grundstück erstellen</Link>
					</div>

				</div>


			</div>
		</div>
    );

}

