import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFolderOn} from "react-icons/ci";

export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente / Öffentliche Mittel ", "/project/"+id+"/drafts/"+draftId+"/documents/") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <p>
					Hier erfahren Sie mehr über die unterschiedlichen Öffentlichen Mittel (kommunale Wohnungsbauförderung) der einzelnen Bundesländer.<br/>
					<br/>
					Gesamtübersicht aller Bundesländer für den schnellen Einstieg:
					<u><a href={"https://www.drklein.de/wohnungsbaufoerderung.html"} target={"_blank"}>https://www.drklein.de/wohnungsbaufoerderung.html</a></u>
					<br/>
					Unterhalb werden wir Ihnen sukzessive weitere Antragsmaterialien und Informationen der einzelnen Bundesländer einstellen. Bitte klicken Sie auf das jeweilige Bundesland für weitere Informationen.<br/>
                </p>
                <div className={"documentSplit"}>
                    <div className={"documentOverview document1"}>
						<div className={"documentElement"}>
							<a href={"/project/"+id+"/drafts/"+draftId+"/documents/category/b"}>
								<div className={"previewImage"}>
									<CiFolderOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Berlin
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={"/project/"+id+"/drafts/"+draftId+"/documents/category/bb"}>
								<div className={"previewImage"}>
									<CiFolderOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Brandenburg
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={"/project/"+id+"/drafts/"+draftId+"/documents/category/ni"}>
								<div className={"previewImage"}>
									<CiFolderOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Niedersachsen
								</div>
							</a>
						</div>
	                    <div className={"documentElement"}>
	                        <a href={"/project/"+id+"/drafts/"+draftId+"/documents/category/nw"}>
	                            <div className={"previewImage"}>
									<CiFolderOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
									Nordrhein-Westfalen
	                            </div>
	                        </a>
	                    </div>
						<div className={"documentElement"}>
	                        <a href={"/project/"+id+"/drafts/"+draftId+"/documents/category/rh"}>
	                            <div className={"previewImage"}>
									<CiFolderOn  className={"icon"} />
	                            </div>
	                            <div className={"name"}>
									Rheinland-Pfalz
	                            </div>
	                        </a>
	                    </div>
						<div className={"documentElement"}>
							<a href={"/project/"+id+"/drafts/"+draftId+"/documents/category/sa"}>
								<div className={"previewImage"}>
									<CiFolderOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Sachsen-Anhalt
								</div>
							</a>
						</div>
					 </div>
                 </div>
			 </div>
        </div>
    );
}