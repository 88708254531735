import React, {useEffect,useState} from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import navi from '../../component/navigation/Component-navigation';
import imageProfile from '../../assets/image/profile.jpg';
import TextField from '@mui/material/TextField';

export default function Page(){

	let avatarImage = null;
	const [user,setUser] = useState(null);
	const navigate = useNavigate();
	// add company ID
	async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user?.attributes);
    }

	useEffect(() => {
		getUser();
	},[]);

	const logOut = () => {
        Auth.signOut();
        navigate('/');
    }

	if(user !== null)
	{
		return(
            <div>
                { navi("Zuträger verwalten", "/user",true) }
                <div id={"page-user-setting"} className={"wrapper-content"}>
	                <div>
	                      folgt
	                </div>
                </div>
            </div>
        );
	}


}