import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';
import {CiFileOn} from "react-icons/ci";


export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Dokumente / Öffentliche Mittel / Rheinland-Pfalz", "/project/"+id+"/drafts/"+draftId+"/documents/category") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <p>
                    Sie haben hier die Möglichkeite Anträge und Formular für das gewählte Grundstück zu erstellen. klicken Sie hierzu auf das Dokument welches Sie erstellen möchten.
                </p>
                <div className={"documentSplit"}>
                    <div className={"documentOverview document"}>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Antrag.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									FÖRDERANTRAG ISB-DARLEHEN
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Datenschutzinformation.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Datenschutzinformation
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Einwilligung_Datenschutz.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Einwilligung Datenschutz
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Legitmationsformular.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Legitmationsformular
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Muster_Rueckgewaehransprueche.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Muster Rückgewähransprüche
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Muster_Verpflichtungserklaerung.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Muster Verpflichtungserklärung
								</div>
							</a>
						</div>
						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/Nachweis_der_Selbsthilfe.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									Nachweis der Selbsthilfe
								</div>
							</a>
						</div>

						<div className={"documentElement"}>
							<a href={`${process.env.PUBLIC_URL}/formes/subview/rh/SEPA_Lastschriftmandat_Wohnraumfoerderung.pdf`}>
								<div className={"previewImage"}>
									<CiFileOn  className={"icon"} />
								</div>
								<div className={"name"}>
									SEPA Lastschriftmandat - Wohnraumförderung
								</div>
							</a>
						</div>

					 </div>
                 </div>
			 </div>
        </div>
    );
}

