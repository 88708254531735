import React,{useState, useEffect} from 'react';
import {BiCheckCircle, BiCircle} from "react-icons/bi";
import {signAction} from "onoffice";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import properties from "../../../configs/propertiesData.json";
import {useNavigate} from "react-router-dom";

function createHmac2(token, secret, data) {
	return signAction(data, token, secret);
}

export default function Page(project, draft, serviceType){

	const [open, setOpen] = useState(false);
	const [selectedDraft, setSelectedDraft] = useState(null);
	const [list, setList] = useState(null);
	const navigate = useNavigate();

	const handleChange = (event) => {
		setSelectedDraft(event.target.value);
	};



	const handleSaveProject = (project,id) => {
		if (typeof id !== "undefined") {

			var productNew = {...project};


			productNew.onofficeCooperativeProjectId = id;

			// set header
			var headers = new Headers();
			headers.append("Content-Type", "application/json");

			// Build json body
			var raw = {...productNew};

			raw.status = productNew.status;
			raw.lastUpdateDate = Date.now();
			raw.json = productNew;
			raw.json.onofficeCooperativeProjectId = id;

			delete(raw.json.id);
			delete(raw.json.userId);
			delete(raw.json.companyId);
			delete(raw.json.lastUpdateDate);
			delete(raw.json.json);
			delete(raw.json.creationDate);


			// build request
			var requestOptions = {
				method: 'PUT',
				headers: headers,
				body: JSON.stringify(raw),
				redirect: 'follow'
			};


			// send request
			fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/projects/"+raw.id, requestOptions)
				.then(error => {

					getOnOfficeEstate();

				})
				.catch(error => console.log('error', error));
		}
	}

	const handleSubmit = (event) => {


		for (var a of draft) {
			if (a?.id === selectedDraft) {
				var headers1 = new Headers();
				headers1.append("Content-Type", "application/json");


				if (typeof project?.addressStreet !== "undefined") {
					var housenumber = project?.addressStreet.split(" ");
				} else {
					var housenumber = null
				}


				var action = {
					actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:create",
					identifier: "",
					parameters: {
						"data": {
							"objektnr_extern": a?.id.slice(0, 8),
							"objektart": "grundstueck",
							"nutzungsart": "wohnen",
							"vermarktungsart": "kauf",
							"objekttyp": "wohnen",
							"strasse": (housenumber !== null) ? project?.addressStreet.substr(0, project?.addressStreet.lastIndexOf(" ") + 1) : project?.addressStreet,
							"hausnummer": (housenumber !== null) ? housenumber[housenumber.length - 1] : "",
							"plz": project?.addressZipCode,
							"ort": project?.addressTown,
							"land": "DEU",
							"InterneBemerkung": project?.name,
							"grundstuecksflaeche": Number(project?.areaTotal),
							"sonstige_angaben": "",
							"objekttitel": "Genossenschaft: " + project?.name + " -  Projekt: " + a?.name,
							"importFreitexte": a?.id,

						}
					},
					resourceid: "estate",
					resourcetype: "estate"
				};

				var requestOptions = {
					method: 'POST',
					headers: headers1,
					body: JSON.stringify({
						"token": "c15335aa313bfab95d9553b2e301528d",
						"request": {
							"actions": [
								createHmac2("c15335aa313bfab95d9553b2e301528d", "f697189168f6a9d213a74078159ca2e2ba41a37a106090c55303b0c95ab0f171", action)
							]
						}
					}),
					redirect: 'follow',
				};


				fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT) + "/onoffice/api", requestOptions)
					.then(res => res.json())
					.then(response => handleSaveProject(project, response?.response?.results?.[0]?.data?.records?.[0]?.id))
					.catch(error => console.error('Error: ', error));


				setOpen(false);
			}
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};


	async function getOnOfficeEstate() {


		var headers = new Headers();
			headers.append("Content-Type", "application/json");


		var action = {
			"actionid": "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
			"resourceid": "",
			"identifier": "",
			"resourcetype": "task",
			"parameters": {
				"data": [
					"Betreff",
					"Status",
					"Stand",
				],
				"relatedEstateId": String(project?.onofficeCooperativeProjectId)
			}
		};


		var requestOptions = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				"token"     : String(process.env.REACT_APP_OO_TOKEN),
				"request"   : {
					"actions" : [
						createHmac2(String(process.env.REACT_APP_OO_TOKEN), String(process.env.REACT_APP_OO_SECRET), action)
					]
				}
			}),
			redirect: 'follow',
		};

		fetch(((process.env.REACT_APP_MODE === 'PROD') ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL_DEVELOPMENT )+"/onoffice/api", requestOptions)
			.then(res => res.json())
			.then(response => { setList(response?.response?.results?.[0]?.data?.records)})
			.catch(error => console.error('Error: ', error));


	}

	useEffect(() => {
		if(typeof project?.onofficeCooperativeProjectId !== "undefined") {
			getOnOfficeEstate();
		}
	}, [project]);


	if(typeof serviceType !== "undefined" && serviceType === "1") {
		if(list !== null || list > 0 || typeof project?.onofficeCooperativeProjectId !== "undefined") {
			return (
				<div>
					<h3 className={"checked"}><BiCheckCircle/><span>Genossenschaft starten</span></h3>
					<div>Sobald das Projekt gestartet wurden finden Sie hier eine Übersicht der aktuelle Aufgaben und dessen Fortschritt.</div>


					{
						list?.map((a) => (Number(a?.elements?.relatedEstateId?.[0]) === project?.onofficeCooperativeProjectId) ? (
							<div className={"taskList"}>
								<div className={"taskListTitle"}>{a?.elements?.Betreff}</div>
								<div>{a?.elements?.Status} ({a?.elements?.Stand} %)</div>
							</div>
						):(""))
					}


				</div>
			);
		} else {
			return (draft?.length > 0) ? (
				<div>
					<h3 className={"checked"}><BiCheckCircle/><span>Genossenschaft starten</span></h3>
					<React.Fragment>
						<Button variant="outlined" className={"subButton"} onClick={handleClickOpen}>
							Projekt wählen
						</Button>
						<Dialog
							open={open}
							onClose={handleClose}

						>
							<DialogTitle>Genossenschaft starten</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Hiermit bitte ich um die Unterstützung bei der Gründung einer Genossenschaft für
									folgendes Projekt.
								</DialogContentText>
								<br/>
								<br/>
								<FormControl fullWidth>
									<InputLabel id="cooperativeFoundation">Projekt wählen</InputLabel>
									<Select
										labelId="cooperativeFoundation"
										id="cooperativeFoundation"
										value={selectedDraft}
										label="cooperativeFoundation"
										onChange={handleChange}
									>
										{
											draft?.map((a) => (<MenuItem value={a?.id}>{a?.name}</MenuItem>))
										}

									</Select>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose}>Abbrechen</Button>
								<Button onClick={handleSubmit} disabled={(selectedDraft !== null) ? false : true}
										style={(selectedDraft === null) ? {opacity: 0.5} : {opacity: 1}} type="submit">Genossenschaft
									starten</Button>
							</DialogActions>
						</Dialog>
					</React.Fragment>
				</div>
			) : ("");
		}
	} else {
		return;
	}



}
