import React, {useEffect,useState} from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { TfiSave } from "react-icons/tfi";
import navi from '../../component/navigation/Component-navigation';
import imageProfile from '../../assets/image/profile.jpg';
import TextField from '@mui/material/TextField';

export default function Page(){

	const [user,setUser] = useState(null);

	// add company ID
    async function getUser() {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user?.attributes);
    }

	useEffect(() => {
        getUser();
    },[]);


	return(
        <div>
            { navi("Einstellungen / Integrationen", "/user",true) }
            <div id={"page-user-integration"} className={"wrapper-content"}>
				<div>
                    <div>
                        <h3>Willkommen im Integration Bereich</h3>
                        <p>
                            Binden Sie das folgende Skript in Ihre Home ein, um Ihre Home&Family Projekte Ihren Kunden anzuzeigen.

                            <ul className={"integrationManual"}>

                                <li>
                                    <span>1</span> Erstellen Sie eine neue leere Seite in Ihrer Homepage. Fügen Sie anschließend den Code in das Contentfeld Ihrer Webseite ein. Sollten Sie WordPress oder vergleichbare Produkte verwenden, wechseln Sie bitte zuerst in die HTML-Ansicht Ihres Editors, um ein Einfügen des Codes zu ermöglichen.
                                </li>
                                <li>
                                    <span>2</span> Kopieren Sie den folgenden Code und fügen Sie diesen in Ihre neuerstellte Seite ein.
                                    <code className={"code"}>{"<iframe style=\"border:0; height:1800px; width:100%;\" src=\"https://main.d1at6m68ssvfy6.amplifyapp.com/"+user?.["custom:companyID"]+"\"></iframe>"}</code>
                                </li>
                                <li>
		                            <span>3</span> Bitte fügen Sie eine der folgenden Versionen (Text oder HTML) in Ihre Datenschutzerklärung ein.
		                            <br/>

		                            <small>HTML</small><br/>
		                            <code className={"code"}>{"<h3>Home & Family</h3>Soweit Sie Ihre Einwilligung erklärt haben, verwendet diese Webseite die Dienste des Softwareanbieters „Home & Family / Geno Portaltechnologie GmbH“. „Home & Family“ ist ein Drittanbieter für die Optimierung unserer Webseite, der uns mit Hilfe von Geo-Daten-Integrationen dabei hilft, projektierte Grundstücke mit Hauskonzepten anzubieten und Antragsstrecke in Formularen anzubieten.<br/><br/><b>Rechtsgrundlage</b> Rechtsgrundlage für diese Datenverarbeitung ist Ihre Einwilligung gem. Art.6 Abs.1 S.1 lit.a DSGVO.<br/><br/> <b>Widerruf</b>  Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Cookie-Einstellungen aufrufen und dort Ihre Auswahl ändern. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung bleibt davon unberührt.<br/><br/><b>Formulardaten</b>Formulardaten mit personenbezogenen Daten sind immer mit der jeweiligen Einverständniserklärung vesehen und setzen Ihre Einwilligung für den jeweiligen Einzelvorgang voraus.<br/><br/>Weitere Angaben zu Art, Umfang und Zweck der Datenbearbeitung finden Sie in der Datenschutzerklärung von Home & Family / Geno Portaltechnologie GmbH <a href=\"https://hf-projekt.de/datenschutz/\" target=\"_blank\">https://hf-projekt.de/datenschutz/</a><br/>"}</code>
		                            <small>Text</small><br/>
                                    <code className={"code1"}>
                                        <h3>Home & Family</h3>Soweit Sie Ihre Einwilligung erklärt haben, verwendet diese Webseite die Dienste des Softwareanbieters „Home & Family / Geno Portaltechnologie GmbH“. „Home & Family“ ist ein Drittanbieter für die Optimierung unserer Webseite, der uns mit Hilfe von Geo-Daten-Integrationen dabei hilft, projektierte Grundstücke mit Hauskonzepten anzubieten und Antragsstrecke in Formularen anzubieten.<br/><br/><b>Rechtsgrundlage</b><br/> Rechtsgrundlage für diese Datenverarbeitung ist Ihre Einwilligung gem. Art.6 Abs.1 S.1 lit.a DSGVO.<br/><br/> <b>Widerruf</b><br/>  Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Cookie-Einstellungen aufrufen und dort Ihre Auswahl ändern. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung bleibt davon unberührt.<br/><br/><b>Formulardaten</b><br/> Formulardaten mit personenbezogenen Daten sind immer mit der jeweiligen Einverständniserklärung vesehen und setzen Ihre Einwilligung für den jeweiligen Einzelvorgang voraus.<br/><br/>Weitere Angaben zu Art, Umfang und Zweck der Datenbearbeitung finden Sie in der Datenschutzerklärung von Home & Family / Geno Portaltechnologie GmbH <a href="https://hf-projekt.de/datenschutz/" target="_blank">{"https://hf-projekt.de/datenschutz/"}</a><br/>
                                    </code>

		                        </li>
                                <li>
                                    <span>4</span> Speichern Sie Ihre Änderungen und veröffentlichen Sie Ihre neue Seite.
                                </li>
                                <li>
                                    <span>5</span>  Fertig
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );



}