import React, { useEffect, useRef, useState } from 'react';
import navi from '../../component/navigation/Component-navigation';
import {useParams} from 'react-router';


export default function Overview(){

	let {id, draftId} = useParams();

    return(
         <div>
            { navi("Privatkunden-Baufinanzierung", "/project/"+id+"/drafts/"+draftId+"/documents/") }
             <div id={"page-project-documents"} className={"wrapper-content"}>
                <div className={""}>

					<div className={"documentBlock"}>
						<div>
							<h3>Über den Ablauf</h3>
							<ol>
								<li>Sie beraten Ihren Kunden in der Erstberatung und erklären die Grundzüge der Finanzierung</li>
								<li>Sie nehmen die Selbstauskunft auf und lassen den Kunden unterschreiben</li>
								<li>Sie senden die folgenden Informationen an die GLS Bank per Mail:

									<ul>
										<li>Gesamtkosten des Bauvorhabens mit Angabe des Hauspreis, Grundstück und Baunebenkosten</li>
										<li>Unterschriebene Selbstauskunft des Endkunden</li>
										<li>Ihre eigenen Kontaktdaten</li>
									</ul>
								</li>
								<li>Die GLS Bank macht eine Plausibilitätsprüfung und meldet sich bei Ihnen</li>
								<li>Die GLS Bank meldet sich beim Endkunden und vereinbart eine Finanzierungsberatung (inkl Tipp der kommunalen Wohnungsbauförderung, falls dies möglich erscheint).</li>
								<li>Sie erhalten ein Feedback der GLS Bank</li>
							</ol>

							<br/><br/>
							<h3>Über die GLS Gemeinschaftsbank eG</h3>
							<b>Nachhaltig und ökologisch seit 1974</b><br/><br/>
							Machen Sie mit Ihrem Geld die Welt nachhaltig. Als größte nachhaltige Bank Deutschlands verändern wir seit 1974 mit dem Geld unserer Kund*innen die Wirtschaft und die Gesellschaft – durch zukunftsweisende Finanzierungen und Investitionen. Nicht maximaler Gewinn ist dabei unser Ziel, sondern eine lebenswerte Welt für alle.
							<br/><br/>
							Mehr erfahren unter <a href={"https://www.gls.de/privatkunden/gls-bank/gls-nachhaltigkeit/"}>https://www.gls.de/privatkunden/gls-bank/gls-nachhaltigkeit/</a>


							<br/><br/>
							<h3>Über die Baufinanzierung der GLS Gemeinschaftsbank eG</h3>
							<br/><br/>
							Der Mensch braucht ein Dach über dem Kopf. Finanzieren Sie Ihr Eigenheim nachhaltig! Ganz gleich ob Eigenheim im Grünen, zentral gelegene Altbauwohnung, Neubau, Kauf, Renovierung oder energetische Sanierung: Ab einer Mindestfinanzierungssumme von 50.000 Euro freuen wir uns, Sie auf Ihrem Weg zu begleiten.
							<br/><br/>
							Mehr erfahren unter <a href={"https://www.gls.de/privatkunden/finanzieren/baufinanzierung/"}>https://www.gls.de/privatkunden/finanzieren/baufinanzierung/</a>

						</div>
						<div className={"documentCard"}>
							<div>

								<h3>Ihr Ansprechpartner</h3>
								<br/>
								<img style={{width:"150px"}} src={`${process.env.PUBLIC_URL}/formes/gls.png`}/>
								<br/>
								GLS Gemeinschaftsbank eG<br/> für Baufinanzierungen mit Fördermittel-Integration<br/><br/>
								Frank Stossberg<br/>
								Kompetenzcenter Nachhaltige Immobilien<br/>
								<br/>
								Telefon: 0234 5797 5265<br/>
								<a href={"mailTo:frank.stossberg@gls.de"}>frank.stossberg@gls.de</a><br/>
								<a href={"https://www.gls.de"} target={"_blank"}>www.gls.de</a><br/>
								<br/>
								Christstr. 9<br/>
								44789 Bochum
							</div>
						</div>
					</div>

                 </div>
			 </div>
        </div>
    );
}